//import { background } from "../../../assets";

export const Rings = () => {
  return (
    <div className="absolute top-1/2 left-1/2 w-[51.375rem] aspect-square border border-n-8/10 rounded-full -translate-x-1/2 -translate-y-1/2">
      <div className="absolute top-1/2 left-1/2 w-[36.125rem] aspect-square border border-n-8/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute top-1/2 left-1/2 w-[23.125rem] aspect-square border border-n-8/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
    </div>
  );
};

export const SideLines = () => {
  return (
    <>
      <div className="absolute top-0 left-5 w-[0.15rem] h-full bg-n-6"></div>
      <div className="absolute top-0 right-5 w-[0.15rem] h-full bg-n-6"></div>
    </>
  );
};


//export const SingleSidelineLeft = () => {
// return (
//    <>
//      <div className="absolute  left-2 w-[0.15rem] h-96 bg-n-6"></div>
//    </>
//  );
//};

export const Example = () => {
  return (
    <>
        {/* <div className="relative flex pt-32  px-10"> */}
          {/* <div className="relative w-2 h-36 bg-black rounded-full animate-pulse"> */}
            <div className="absolute  w-2 h-52 ml-10 -mt-10 bg-slate-700 rounded-full"></div>
          {/* </div> */}   
        {/* </div> */}   
    </>
  );
};


export const SingleSidelineRight = () => {
  return (
    <>
      {/* <div className="absolute  left-2 w-[0.15rem] h-96 bg-n-6"></div> */}
      <div className="absolute w-2 h-52 right-15 py-10 bg-slate-700 rounded-full"></div>   
    </>
  );
};

export const BackgroundCircles = () => {
  return (
    <>
      <div className="absolute top-[4.4rem] left-16 w-3 h-3 bg-gradient-to-b from-[#DD734F] to-[#1A1A32] rounded-full"></div>
      <div className="absolute top-[12.6rem] right-16 w-3 h-3 bg-gradient-to-b from-[#B9AEDF] to-[#1A1A32] rounded-full"></div>
      <div className="absolute top-[26.8rem] left-12 w-6 h-6 bg-gradient-to-b from-[#88E5BE] to-[#1A1A32] rounded-full"></div>
    </>
  );
};

export const HambugerMenu = () => {
  return (
    <div className="absolute inset-0 pointer-events-none lg:hidden">

      <Rings />



      <BackgroundCircles />
    </div>
  );
};
