import React, {ReactNode} from 'react';
import ButtonSvg from '../../assets/svg/ButtonSvg';
//import { Link } from 'react-router-dom';

interface ButtonProps {
    className?: string;
    href?: string;
    onClick?: () => void;
    children: ReactNode;
    px?: string;
    black?: boolean;



}

const Button: React.FC<ButtonProps> = ({ className, href, onClick, children, px, black}) => {


    const classes = `button relative inline-flex-column items-centet justify-center h-11 transition-colors hover:text-color-1 ${
        px || "px-7"
    } ${black ? "text-n-1" : "text-n-1"} ${className || ""}`; 

    const spanClasses = "relative z-10"

    const renderButton = () => (
        <button className={classes} onClick={onClick}>
            <span className={spanClasses}>{children}</span>
            <ButtonSvg black={black} />
        </button>
    );

    const renderLink = () => (
        <a href={href} className={classes}>
            <span className={spanClasses}>{children}</span>
        </a>
    );

    return href ? renderLink() : renderButton();
}

export default Button;
