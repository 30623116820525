import * as THREE from "three";
import { useRef, useState, useMemo, useEffect, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Billboard, Text, TrackballControls } from "@react-three/drei";
import { useEmotionData } from "src/context/EmotionDataContext";

// Predefined list of words (raga names)
const raga_list = [
  "Ahira bhairav",
  "Alahaiya bilaval",
  "Bairagi",
  "Basant",
  "Bhairav",
  "Bhup",
  "Bihag",
  "Bilasakhani todi",
  "Bagesri",
  "Darbari",
  "Des",
  "Gaud malhar",
  "Hamsadhvani",
  "Khamaj",
  "Kedar",
  "Lalit",
  "Madhukauns",
  "Madhuvanti",
  "Miyan malhar",
  "Malkauns",
  "Marva",
  "Puriya dhanasri",
  "Ragesri",
  "Todi",
  "Yaman kalyan",
  "Abhogi",
  "Sri",
  "Suddh sarang",
  "Jog",
  "Marubihag",
];

// Define type for Word component props
type WordProps = {
  children: React.ReactNode;
  position: [number, number, number];
  fontSize?: number;
  color?: string; // Added color prop
};

function Word({
  children,
  fontSize = 2.5,
  color = "green",
  ...props
}: WordProps) {
  const defaultColor = new THREE.Color(color);
  const hoverColor = new THREE.Color("blue");
  const ref = useRef<THREE.Mesh>(null);
  const [hovered, setHovered] = useState(false);

  const over = (e: any) => {
    e.stopPropagation();
    setHovered(true);
  };

  const out = () => setHovered(false);

  useEffect(() => {
    if (hovered) document.body.style.cursor = "pointer";
    return () => {
      document.body.style.cursor = "auto";
    };
  }, [hovered]);

  useFrame(() => {
    if (ref.current) {
      const material = ref.current.material as THREE.Material;
      if ("color" in material) {
        (material.color as THREE.Color).lerp(
          hovered ? hoverColor : defaultColor,
          0.1
        );
      }
    }
  });

  return (
    <Billboard {...props}>
      <Text
        ref={ref}
        fontSize={fontSize}
        color={color}
        onPointerOver={over}
        onPointerOut={out}
        onClick={() => console.log(`Clicked on: ${children}`)}
        children={children}
      />
    </Billboard>
  );
}

// Define type for Cloud component props
type CloudProps = {
  count?: number;
  radius?: number;
};

function Cloud({ count = 5, radius = 25 }: CloudProps) {
  const { predictedRagaName } = useEmotionData(); // Access predicted raga name
  const words = useMemo(() => {
    const temp: [THREE.Vector3, string, number, string][] = [];
    const spherical = new THREE.Spherical();
    const phiSpan = Math.PI / (count + 1);
    const thetaSpan = (Math.PI * 2) / count;

    raga_list.forEach((raga, index) => {
      if (raga === predictedRagaName) {
        temp.push([new THREE.Vector3(0, 0, 0), raga, 20, "red"]); // Center position, larger font, green color
      } else {
        const i = Math.floor(index / count) + 1;
        const j = index % count;
        temp.push([
          new THREE.Vector3().setFromSpherical(
            spherical.set(radius, phiSpan * i, thetaSpan * j)
          ),
          raga,
          2.5,
          "black", // Default color for surrounding words
        ]);
      }
    });

    return temp;
  }, [count, radius, predictedRagaName]);

  return (
    <>
      {words.map(([pos, word, fontSize, color], index) => (
        <Word
          key={index}
          position={pos.toArray() as [number, number, number]}
          fontSize={fontSize}
          color={color}
          children={word}
        />
      ))}
    </>
  );
}

export default function App() {
  return (
    <div className="">
      <div
        className="flex relative h-[40rem] w-[55rem] mt-6 bg-n-2 rounded-lg"
        // ref={wordcloudref}
      >
        <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 35], fov: 90 }}>
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} intensity={1} />
          <fog attach="fog" args={["#202025", 0, 80]} />
          <Suspense fallback={null}>
            <group rotation={[10, 10.5, 10]}>
              <Cloud count={8} radius={20} />
            </group>
          </Suspense>
          <TrackballControls />
        </Canvas>
        {/* <button
          className="absolute top-0 left-[55.5rem] bg-blue-500 text-white rounded-full
              p-2 hover:bg-blue-700 transition-all duration-200"
          onClick={handleDownload}
        >
          <FaDownload />
        </button> */}
      </div>
      //{" "}
    </div>
  );
}
