import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

interface CustomCardProps {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

interface VideoProps extends CustomCardProps {
  videoId: string;
}

const Video: React.FC<VideoProps>  = () => {
  const { videoId } = useOutletContext<{ videoId: string }>(); // Get the videoId from the Outlet context
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideo = async () => {
      if (!videoId) return; // Don't fetch if no videoId available

      try {
        const response = await fetch(`https://madai.app/video/api/uploadvideo/${videoId}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const videoBlob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(videoBlob);
        setVideoUrl(videoObjectUrl); // Set the video URL to display in the video element
      } catch (error) {
        console.error('Failed to fetch video:', error);
        alert('Failed to fetch video. Please check the console for more details.');
      }
    };

    fetchVideo();
  }, [videoId]); // Re-fetch the video when videoId changes

  return (
    <div >
      {videoUrl ? (
        <video controls className="w-full h-auto">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div>No video available</div>
      )}
    </div>
  );
};

export default Video;

