import React from 'react';
import Section from './Section.component';
import { brainwaveServices } from 'src/constants';
//import {  SingleSidelineRight } from './design/Header';
//import { GradientLight } from './design/Benefits';
import Heading from './Heading';
import { service2, service4 } from 'src/assets';
import { check } from 'src/assets';
import Generating from './design/Generating';
import { PhotChatMessage } from './design/Services';


const HowtoUse = () => {
  return (
    <Section id='roadmap'>

      <div className="container">
        {/* <SingleSidelineRight /> */}
        <Heading className="md:max-w-md lg:max-w-2xl "
          title="Let's Start, What You Have"
          text="MadAI unlocks the potential of AI-powered applications" />

        <div className="relative">
          <div className="relative z-1 flex items-center h-[39rem] mb-5 p-8 
          border border-n-1/10 rounded-3xl overflow-hidden lg:p-20 xl:h-[46rem] ">

            <div className='absolute top-0 left-0 w-full h-full pointer-events-none md:w-3/5 xl:w-auto '>
              <img
                className='w-[50rem] h-full object-cover
                  '
                width={800}
                height={730}
                src={service4}
                alt="Smartest AI"
              />
            </div>

            <div className="relative z-1 max-w-[17rem] ml-auto">
              <h4 className='h4 mb-4'>Smartest AI</h4>
              <p className='body-2 mb-[3rem] text-n-3'>MadAI unlocks the potential of AI-powered applications</p>
              <ul className='body-2'>
                {brainwaveServices.map((item, index) => (
                  <li key={index} className='flex items-start py-4 border-t border-n-6'>
                    <img src={check} width={24} height={24} alt="" />
                    <p className='ml-4 '>{item}</p>
                  </li>
                ))}

              </ul>
            </div>

            <Generating className="absolute  right-32 bottom-4
             border-n-1/10 border lg:left-1/2 lg-right-auto lg:bottom-8 lg:-translate-x-[30rem]"/>

          </div>


          <div className='relative z-1 grid gap-5 lg:grid-cols-2'>

            <div className='relative min-h-[39rem] border border-n-1/10 
            rounded-3xl
                  overflow-hidden'>

              <div className='absolute inset-0'>
                <img src={service2} className='h-full w-full object-cover'
                  width={630} height={750} alt="robot" />
              </div>

              <div className="absolute inset-0 flex flex-col justify-end p-8
                              bg-gradient-to-bfrom-n-8/90 to-n-8/90 lg:p-15">
                <h4 className='h4 mb-4'>Smartest AI</h4>
                <p className='body-2 mb-[3rem] text-n-3'>MadAI unlocks the potential of AI-powered applications</p>
              </div>

              <PhotChatMessage/>

            </div>

            <div className='p-4 bg-n-7 rounded-3xl overflow-hidden lg:min-h-[46rem]'>
                  <div className='py-12 px-4 xl:px-8 '>
                    <h4 className=''>Script generation</h4>

                  </div>
            </div>
          </div>
        </div>
      </div>
    </Section>

  )
}
export default HowtoUse;
