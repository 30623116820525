import React from 'react';
import { useLocation } from 'react-router-dom';

const ProductDetails: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the form data from the query parameters
  const formData = {
    Brand: queryParams.get('Brand'),
    GenericStrength: queryParams.get('GenericStrength'),
    BatchNo: queryParams.get('BatchNo'),
    DateOfManufacture: queryParams.get('DateOfManufacture'),
    Expiry: queryParams.get('Expiry'),
    MRP: queryParams.get('MRP'),
    Manufacturer: queryParams.get('Manufacturer'),
  };

  return (
    <div className="flex justify-center items-center h-screen bg-cyan-900">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full">
        <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Product Details</h2>
        <div className="space-y-4">
          <div className="border-b pb-2">
            <p className="text-lg font-medium text-gray-800">Brand:</p>
            <p className="text-gray-600">{formData.Brand}</p>
          </div>
          <div className="border-b pb-2">
            <p className="text-lg font-medium text-gray-800">Generic & Strength:</p>
            <p className="text-gray-600">{formData.GenericStrength}</p>
          </div>
          <div className="border-b pb-2">
            <p className="text-lg font-medium text-gray-800">Batch No:</p>
            <p className="text-gray-600">{formData.BatchNo}</p>
          </div>
          <div className="border-b pb-2">
            <p className="text-lg font-medium text-gray-800">Date of Manufacture:</p>
            <p className="text-gray-600">{formData.DateOfManufacture}</p>
          </div>
          <div className="border-b pb-2">
            <p className="text-lg font-medium text-gray-800">Expiry:</p>
            <p className="text-gray-600">{formData.Expiry}</p>
          </div>
          <div className="border-b pb-2">
            <p className="text-lg font-medium text-gray-800">MRP:</p>
            <p className="text-gray-600">{formData.MRP}</p>
          </div>
          <div>
            <p className="text-lg font-medium text-gray-800">Manufacturer:</p>
            <p className="text-gray-600">{formData.Manufacturer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
