import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom'; // Import the useOutletContext hook

interface CustomCardProps {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

interface GraphProps extends CustomCardProps {
  videoId: string;
}

const Graph: React.FC<GraphProps> = ({ className = '', onClick, children}) => {
  const [graphs, setGraphs] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedGraph, setSelectedGraph] = useState<any>(null);

    // Get the videoId and projectName from the context passed by Outlet
    const { videoId, projectName } = useOutletContext<{ videoId: string; projectName: string }>();


  useEffect(() => {
    const fetchGraphs = async () => {
      if (!videoId) return; // Don't fetch if videoId is not set
      try {
        const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
        if (!token) throw new Error('No auth token found');

        const response = await fetch(`https://madai.app/graph/api/graphs/${videoId}`, {
          headers: {
            'Authorization': `Bearer ${JSON.parse(token)}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setGraphs(result.graphs);
      } catch (error) {
        console.error('Failed to fetch graphs:', error);
        alert('Failed to fetch graphs. Please check the console for more details.');
      }
    };

    fetchGraphs();
  }, [videoId]);

  const handleOpen = (graph: any) => {
    setSelectedGraph(graph);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGraph(null);
  };

  const handleDownload = () => {
    if (selectedGraph) {
      const link = document.createElement('a');
      link.href = `data:image/png;base64,${selectedGraph.ImageData}`;
      link.download = `${selectedGraph.GraphType}.png`;
      link.click();
    }
  };

  return (
    <>
      <div
        id='graph'
        className={`relative flex flex-col h-full overflow-hidden text-gray-700 transition-opacity shadow-md cursor-pointer w-65 rounded bg-clip-border hover:opacity-90 ${className}`}
      >
        <div className="flex flex-col h-[457px] overflow-y-scroll space-y-4">
          {graphs.map((graph, index) => (
            <div
              key={index}
              className="relative flex flex-col h-80 text-gray-700 transition-opacity bg-white shadow-md cursor-pointer w-full rounded-xl bg-clip-border hover:opacity-90"
              onClick={() => handleOpen(graph)}
            >
              <img
                alt={graph.GraphType}
                className="object-cover object-center w-full h-full"
                src={`data:image/png;base64,${graph.ImageData}`}
              />
            </div>
          ))}
        </div>
      </div>

      {open && selectedGraph && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60"
          onClick={handleClose}
        >
          <div
            className="relative m-4 w-3/4 min-w-[80%] max-w-[80%] bg-white rounded-lg shadow-2xl"
            onClick={(e) => e.stopPropagation()} // Prevent closing the dialog when clicking inside it
          >
            {/* Top section */}
            <div className="flex items-center justify-end p-2 text-sm font-semibold text-blue-gray-900">
              <button
                className="bg-gray-900 py-1 px-2 text-white rounded-lg shadow-md"
                type="button"
                onClick={handleDownload}
              >
                Download
              </button>
            </div>

            {/* Image section */}
            <div className="p-0 border-t border-b border-blue-gray-100">
              <img
                alt={selectedGraph.GraphType}
                className="h-[40rem] w-full  "
                src={`data:image/png;base64,${selectedGraph.ImageData}`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Graph;

