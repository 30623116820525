import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useState } from 'react';
import '../../styles/madai.css'

interface MadaiFormProps {
    onVideoUpload: (videoPath: string) => void;
}

const MadaiForm: React.FC<MadaiFormProps> = ({ onVideoUpload }) => {
    const { register, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit: SubmitHandler<Record<string, any>> = async (data) => {
        const formData = new FormData();
        formData.append('projectname', data.projectname);
        formData.append('videotitle', data.videotitle);
        formData.append('videofile', data.videofile[0]);

        // Append CSV file if only exists
        if (data.csvfile && data.csvfile.length > 0) {
            formData.append('csvfile', data.csvfile[0]);
        }

        setIsLoading(true); // Start loading animation when the submit button is clicked

        try {
            const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
            if (token) {
                const response = await fetch('https://madai.app/algorithm/api/video-csv', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(token)}`,
                    },
                    body: formData,
                });
                const result = await response.json();
                if (response.ok) {
                    console.log('Video ID:', result.video_id);
                    alert(`File upload successful. Video ID: ${result.video_id}`);
                    // Log the video_id to the console
                    localStorage.setItem('projectName', result.project_name);
                    localStorage.setItem('videoId', result.video_id);
                    localStorage.setItem('uploadedVideoFilename', result.video_filename);
                    
                    // Notify parent component of the uploaded video filename
                    onVideoUpload(result.video_filename);
                    
                    // Reload the page to pick up new values
                    window.location.reload();
                } else {
                    alert('Failed to upload');
                    console.error('Upload error:', result.error);
                }
            } else {
                alert('Authorization token is missing');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to upload');
        }
        finally {
            setIsLoading(false); // Stop loading animation after the file upload process completes
        }
    };

    return (
<form className="bg-white shadow-md rounded px-4 pt-4 pb-4 mb-4 h-full relative flex items-center justify-center" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className={`${isLoading ? 'opacity-50' : ''}`}>
                <div className="mb-4 mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projectname">
                        Project Name
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="projectname"
                        type="text"
                        {...register('projectname', { required: true })}
                    />
                </div>
                <div className="mb-4 mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videotitle">
                        Video Title
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="videotitle"
                        type="text"
                        {...register('videotitle', { required: true })}
                    />
                </div>
                <div className="mb-4 mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="csvfile">
                        Upload CSV (Optional)
                    </label>
                    <input
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-black file:text-white hover:file:bg-gray-800"
                        id="csvfile"
                        type="file"
                        accept=".csv"
                        {...register('csvfile')}
                    />
                </div>
                <div className="mb-4 mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videofile">
                        Upload Video
                    </label>
                    <input
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-black file:text-white hover:file:bg-gray-800"
                        id="videofile"
                        type="file"
                        accept="video/*"
                        {...register('videofile', { required: true })}
                    />
                </div>
                <div className="flex items-center justify-center mt-8 mb-8">
                    <button
                        className={`bg-black hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                            isLoading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        type="submit"
                        disabled={isLoading} // Disable the button while loading
                    >
                        {isLoading ? 'Uploading...' : 'Submit'}
                    </button>
                </div>
            </div>

            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                    <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12"></div>
                </div>
            )}
        </form>
    );
};

export default MadaiForm;
