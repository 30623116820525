import React, { useEffect, useRef } from 'react';
import Section from './Section.component';
//import Button from './Button.component';
import ChatBoxMain from "../../assets/chatboxmain.png";
// import { Gradient } from './design/Hero';

import{ ScrollParallax } from 'react-just-parallax';

// import { Fade, Slide } from 'react-awesome-reveal';
import ScrollReveal from 'scrollreveal';
import "../../index.css";
import Notification from './design/Notification';
import { SideLines} from './design/Header';
//import { BackgroundCircles } from './design/Header';
//import { BottomLine } from './design/Hero';
//import { Link } from 'react-router-dom';
import { useAuth } from '../Authentication/Auth';
import { FaArrowRight } from "react-icons/fa";




const Hero: React.FC = () => {
    const parallaxRef = useRef(null);

    const [logged]=useAuth();

    useEffect(() => {

        const sr = ScrollReveal();

        sr.reveal('.fade-in', {
            duration: 1000,
            distance: '50px',
            origin: 'top',
            easing: 'ease-in-out',
            delay: 500,
            reset: true,

        });

        sr.reveal('.hero-p', {
            duration: 1000,
            distance: '50px',
            origin: 'right',
            easing: 'ease-in-out',
            delay: 600,
            reset: true,
        });

        sr.reveal('.hero-button', {
            duration: 1000,
            distance: '50px',
            origin: 'bottom',
            easing: 'ease-in-out',
            delay: 700,
            reset: true,
        });

        sr.reveal('.textitem', {
            duration: 1000,
            distance: '50px',
            origin: 'bottom',
            easing: 'ease-in-out',
            delay: 800,
            reset: true,
        });



        sr.reveal('.imgitem', {
            duration: 1000,
            distance: '60px',
            origin: 'right',
            easing: 'ease-in-out',
            delay: 900,
            reset: true,
        });


    }, []);

    return (
        <Section
            className='pt-[12rem] -mt-[6.25rem]'
            crosses
            crossesOffset='lg:translate-y-[42.25rem]'
            customPaddings
            id='hero'
        >
            <div className='container relative' ref={parallaxRef}>
                <div className='relative z-1 max-w-[62rem] mx-auto text-center mb-[4rem] md:mb-20 lg:mb-[6rem]'>

                    <h1 className="fade-in h1 mb-10">
                        Create Video Script. Analyze. Chat.
                        <span className='inline-block relative'>
                            Together
                        </span>
                    </h1>

                    <p className='hero-p body-2 max-w-7xl mx-auto mb-6 text-n-7 lg:mb-8'>
                        This generative AI-powered platform crafts tailored video scripts by analyzing
                        emotions in uploaded videos and audio, including music chords, and engaging in
                        interactive chats to produce the most suitable scripts.
                    </p>

                    {/* <Button black className='hero-button' href="/madai">
                        Start now
                    </Button> */}
                    {logged?
                        (
                            <>
                                <div className='hero-button flex items-center justify-center ' >
                                    <a href="/madai" className="bg-black text-white hover:text-gray-400 rounded-full pl-[2rem] pr-[3rem] py-2 p-2 ">
                                        Start<FaArrowRight className='bg-black absolute ml-14 -mt-[1.3rem]'/>
                                    </a>
                                </div>
                            </>
                        )
                        :
                        (    <> 
                            <div className='hero-button flex items-center justify-center ' >
                                <a href="/login" className="bg-black text-white hover:text-gray-400 rounded-full pl-[2rem] pr-[3rem] py-2 p-2 ">
                                    Log in<FaArrowRight className='bg-black absolute ml-16 -mt-[1.3rem]'/>
                                </a>
                            </div>
                            </>  
                        )
                    }


                </div>

                <div className=' shadow-2xl relative flex flex-col md:flex-row py-6 px-6 bg-stone-200 z-1 h-[38rem]' style={{boxShadow: "0 8px 16px 10px rgba(0, 0, 0, 0.2), 10px 16px 40px 10px rgba(0, 0, 0, 0.19)"}}>
                    {/* <div className='w-[380rem] rounded-xl bg-zinc-700 flex flex-col md:flex-row fixed'> */}

                    <div className='textitem lg:items-center lg:justify-between lg:py-10 lg:px-4 lg:w-[30rem] '>
                        <span className='uppercase tracking-wide  text-black font-bold text-4xl'>MadAI<p className='lowercase text-zinc-700 font-medium text-2xl'>creates video script and analyze the audio emotion and video emotion of the video
                        </p></span>

                        <ScrollParallax isAbsolutelyPositioned>
                            <Notification className="hidden absolute right-[5.5rem]
                    bottom-[4rem] w-[18rem] xl:flex"
                                title="Script generating" />
                        </ScrollParallax>

                        <ScrollParallax isAbsolutelyPositioned>
                            <Notification className="hidden absolute right-[5.5rem]
                    bottom-[9rem] w-[18rem] xl:flex"
                                title="Analyzing" />
                        </ScrollParallax>

                        <ScrollParallax isAbsolutelyPositioned>
                            <Notification className="hidden absolute right-[5.5rem] 
                    bottom-[14rem] w-[18rem] xl:flex "
                                title="Chatting" />
                        </ScrollParallax>
                    </div>
                    {/* </div> */}

                    <div className='imgitem rounded-lg shadow hover:shadow-2xl  relative 
                    flex flex-col md:flex-row py-6 px-6 w-[72%] bg-stone-400' style={{ transform: "translate(80px, 100px)" }}>
                        <img src={ChatBoxMain} className=' w-[100%] ' width={1000} alt='chatboxmain' />


                    </div>

                </div>


                {/* <div className='hidden absolute  left-12 w-1 
                        h-48 bg-red-700 pointer-events-none md:block lg:left-32 xl:left:10' /> */}

                {/* <SingleSidelineLeft/> */}
                
                {/* <BackgroundCircles /> */}

            </div>
            <SideLines />
            
        </Section>
    );
};

export default Hero;
