import React from "react";
import Section from "./Section.component";
// import Section from './Section.component';

const Footer = () => {
  return (
    <Section id="footer">
      <footer className="bg-black text-center text-surface/75 dark:bg-neutral-700 text-white lg:text-left">
        <div className="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 lg:justify-between">
          <div className="me-12 hidden lg:block">
            <span>Get connected with us on social networks:</span>
          </div>
          <div className="flex justify-center">
            {[
              {
                path: "M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z",
              },
              {
                path: "M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z",
              },
            ].map((icon, idx) => (
              <a
                key={idx}
                href="#!"
                className="me-6 [&>svg]:h-4 [&>svg]:w-4"
                aria-label={`Social Icon ${idx + 1}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 320 512"
                >
                  <path d={icon.path} />
                </svg>
              </a>
            ))}
          </div>
        </div>

        <div className="mx-6 py-10 text-center md:text-left">
          <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
            <div>

            </div>
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Products
              </h6>
              {["Alchemist", "Third AI"].map((product, idx) => (
                <p key={idx} className="mb-4">
                  <a href="#!">{product}</a>
                </p>
              ))}
            </div>
            <div>
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Useful links
              </h6>
              {["Pricing", "Settings", "Orders", "Help"].map((link, idx) => (
                <p key={idx} className="mb-4">
                  <a href="#!">{link}</a>
                </p>
              ))}
            </div>
            <div className="">
              <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Contact
              </h6>
              {[
                {
                  text: "81/A, Temple rd, Sri Jayawardenepura Kotte, Sri Lanka",
                  icon: "LocationIconPath",
                },
                { text: "info@republic.boutique", 
                  icon: "MailIconPath" 
                },
                { 
                  text: "+ 94 112 82 92 91", 
                  icon: "PhoneIconPath" 
                },
              ].map((contact, idx) => (
                <p key={idx} className="mb-4 flex items-center">
                  <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                    <svg>{contact.icon}</svg>
                  </span>
                  {contact.text}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-black/5 p-6 text-center">
          <span>© 2024 Copyright: </span>
          <a className="font-semibold" href="https://boutiqueagency.ai/">
            Boutique Republic
          </a>
        </div>
      </footer>
    </Section>
  );
};

export default Footer;

