import React from "react";
import WordCloud from "../Graphs/WorldCloud";
// import Radar from "../Graphs/Radar";
// import Sum from "../Graphs/SumofEmotion"



const Card: React.FC = () => {
  return (
    <div className="cursor-pointer group  items-center justify-center 
    my-6 bg-gray-300 shadow-sm border border-slate-200 rounded-lg 
    w-auto hover:shadow-lg transition-shadow duration-300">
      <div className="relative h-60 m-2.5 overflow-hidden text-white rounded-md">
        {/* Image section */}
        <div className="relative h-full w-full">
          <WordCloud/>
        </div>
        {/* Sliding Text Section */}
        <div className="absolute bottom-0 left-0 w-full bg-black p-4 
        text-slate-800 transform translate-y-full group-hover:translate-y-20 
        transition-transform duration-300 ease-out">
          {/* <h6 className="text-lg font-semibold">Successful Seed Round</h6> */}
          <p className="text-sm mb-16 p-2 text-white">
Here you can see which raga is most dominant in the the video
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;

