import { createAuthProvider } from 'react-token-auth';

// Define the keys for storing tokens in localStorage
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

// Create the auth provider using `react-token-auth`
export const { useAuth, authFetch, login, logout } = createAuthProvider({
    getAccessToken: () => localStorage.getItem(ACCESS_TOKEN_KEY) || '',
    storage: {
        getItem: (key: string) => localStorage.getItem(key),
        setItem: (key: string, value: string) => localStorage.setItem(key, value),
        removeItem: (key: string) => localStorage.removeItem(key),
    },
    onUpdateToken: () =>
        fetch('/auth/refresh', {
            method: 'POST',
            body: JSON.stringify({ refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY) }), // Using the refresh token from localStorage
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(r => {
            if (!r.ok) {
                throw new Error('Token refresh failed');
            }
            return r.json();
        })
        .then(newToken => {
            localStorage.setItem(ACCESS_TOKEN_KEY, newToken.accessToken);
            localStorage.setItem('ACCESS_TOKEN_EXPIRY', (Date.now() + (12 * 60  * 60 * 1000)).toString()); // 2-minute expiry
            localStorage.setItem(REFRESH_TOKEN_KEY, newToken.refreshToken);
            return newToken;
        }),
});
