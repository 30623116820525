import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';

interface QrForms {
  Brand: string;
  GenericStrength: string;
  BatchNo: string;
  DateOfManufacture: string;
  Expiry: string;
  MRP: number;
  Manufacturer: string;
}

const Form: React.FC = () => {
  const { register, handleSubmit, reset } = useForm<QrForms>();
  const [qrData, setQrData] = useState<string | null>(null);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<QrForms> = (data) => {
    // Encode form data in URL parameters
    const queryParams = new URLSearchParams(data as any).toString();
    const qrString = `https://madai.app/product-details?${queryParams}`;
    setQrData(qrString); // Set QR code to contain the encoded form data
    reset(); // Reset the form
  };

  const downloadQR = () => {
    const canvas = document.getElementById('qr-gen') as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-cyan-900">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full"
      >
        <h2 className="text-2xl font-bold mb-4 text-center">QR Form</h2>

        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Brand</label>
          <input
            {...register('Brand')}
            placeholder="Enter Brand"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Generic & Strength</label>
          <input
            {...register('GenericStrength')}
            placeholder="Enter Generic & Strength"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Batch No</label>
          <input
            {...register('BatchNo')}
            placeholder="Enter Batch No"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Date of Manufacture</label>
          <input
            type="date"
            {...register('DateOfManufacture')}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Expiry</label>
          <input
            type="date"
            {...register('Expiry')}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">MRP</label>
          <input
            type="number"
            {...register('MRP')}
            placeholder="Enter MRP"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Manufacturer</label>
          <input
            {...register('Manufacturer')}
            placeholder="Enter Manufacturer"
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Submit
        </button>
      </form>

      {qrData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold mb-4">QR Code Generated</h2>
            <QRCodeCanvas id="qr-gen" value={qrData} size={150} />
            <div className="mt-4">
              <button
                onClick={downloadQR}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Download QR Code
              </button>
              <button
                onClick={() => setQrData(null)}
                className="ml-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
