import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation

interface ResultProps {
  colorCodes: { [code: string]: string };
  detectedColor: string;
}

const Result: React.FC = () => {
  const location = useLocation(); // Access location
  const { colorCodes, detectedColor } = location.state || {}; // Destructure the state

  if (!colorCodes || !detectedColor) {
    return <div>Error: No data received.</div>; // Handle case where no data is received
  }

  return (
    <div className="flex h-screen items-center justify-center bg-gradient-to-t from-[#008fc9] to-[#050394] font-sans relative">
      <div className="relative w-full  flex flex-col items-center justify-center p-5 rounded-lg text-center z-10 -top-20 lg:max-w-4xl ">
        
        {/* Result Container */}
        <div className="relative bg-white p-5 rounded-lg shadow-lg w-full h-[30rem] max-w-2xl lg:w-full">
          <h1 className="text-xl md:text-2xl mb-5">Dental Color Shade Guide</h1>

          <div className="flex flex-wrap justify-center">
            {Object.entries(colorCodes).map(([code, rgb]) => (
              <div
                key={code}
                className={`w-8 h-8 text-xs md:w-16 md:h-16 inline-block m-2 border lg:text-xl ${
                  detectedColor === code ? 'border-2 border-red-500' : 'border-black'
                }`}
                // Ensure the RGB format is correctly applied
                style={{ backgroundColor: `rgb(${rgb})` }}
              >
                {code}
              </div>
            ))}
          </div>

          <p className="mt-5 text-lg md:text-xl font-bold p-2 border-2 border-red-500 rounded text-red-500">
            Detected Color is: <span>{detectedColor}</span>
          </p>

          <p className="mt-2 text-sm md:text-base">
            Note: Results may vary based on light conditions and camera quality of your device.
          </p>
        </div>
      </div>

      {/* Background Image at the bottom */}
      {/*<img
        src="/static/image/SIgnal Icon-01.png"
        alt="Background"
        className="absolute bottom-0 w-[95%] h-[30%] object-cover lg:w-[50%] sm:h-[30%]"
      />*/}
    </div>
  );
};

export default Result;
