import Heading from './Heading';
import Section from './Section.component';
import { benefits } from 'src/constants';
import { SideLines } from './design/Header';
//import { GradientLight } from './design/Benefits';
import { Example } from './design/Header';
//import { BottomLine } from './design/Hero';


const Why = () => {
  return (
    <Section id="features" crossesOffset='lg:translate-y-[42.25rem]'
      customPaddings className='bg-slate-800'>

      <div className='container relative z-2 mt-36'>
      <Example/>
        <Heading className="md:max-w-md lg:max-w-2xl "
          title="Create your Storyboard Smarter, Not Harder with MadAI" />
          

        <div className='flex flex-wrap gap-16 mb-10 '>
          {benefits.map((item) => (
            <div className='block relative p-0.5 bg-no-repeat bg-[length:100%_100%]
                md:max-w-[24rem] bg-gray-300 shadow hover:shadow-2xl rounded-xl'
             // style={{
             //   backgroundImage: `url(${item.backgroundUrl})`,
             // }}

             // key={item.id}
            >

              <div className='relative z-2 flex flex-col
              min-h-[22rem] p-[2.4rem] pointer-events-none '>
                <h5 className='h5 mb-5 font-semibold'>{item.title}</h5>
                <p className='body-2 mb-6 text-n-7'>{item.text}</p>

                <div className='flex items-center mt-auto'>
                  <img src={item.iconUrl} width={48} height={48} alt={item.title} />
                </div>
              </div>

             {/* {item.light && <GradientLight />}*/}

              <div className="absolute inset-0.5 bg-stone-400 rounded-xl"
                style={{ clipPath: "url(#benefits)" }}
              >

               {/* <div className='absolute inset-0 opacity-0 transition-opacity
                  hover:opacity-10'>
                  {item.imageUrl && (
                    <img
                      src={item.imageUrl}
                      width={380}
                      height={362}
                      alt={item.title}
                      className='w-full h-full object-cover' />
                  )}
                </div>*/}

              </div>


            </div>
          ))}

        </div>
        
      </div>
      
      <SideLines />
      
    </Section>

  );
};

export default Why;
