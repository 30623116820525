import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import MadAI from "../../../assets/MAD-03.png";
import { BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { SideBarCom } from 'src/constants';
import { IconType } from 'react-icons';

interface ChatHistoryProps {
  userId: string;
  uploadedVideoId?: string;
  videoTitle: string;
}

interface Conversation {
  id: number;
  name: string;
  timestamp: string;
  video_id: string;
  video_filename: string;
  messages: Array<{ role: string; message: string }>;
}

const Layout: React.FC<ChatHistoryProps> = ({ userId, uploadedVideoId, videoTitle }) => {
  const [expanded, setExpanded] = useState(true);
  const [active, setActive] = useState<string | null>(null);
  const [chatHistories, setChatHistories] = useState<Conversation[]>([]);
  const [selectedChat, setSelectedChat] = useState<Conversation | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [expandedVideoTitle, setExpandedVideoTitle] = useState<{ [key: string]: boolean }>({});
  const [activeChatId, setActiveChatId] = useState<number | null>(null); // Track active chat ID
  const [activeVideoTitle, setActiveVideoTitle] = useState<string | null>(null); // Track active project name

  const handleItemClick = (id: string) => {
    setActive(id);
  };

  // "fetchChatHistory" is a function responsible for fetching the user's entire chat history from the backend.
  const fetchChatHistory = async () => {
    if (userId) {
      try {
        const response = await fetch(`https://madai.app/chatfun/api/get_conversations?user_id=${userId}`);
        const data = await response.json();
        setChatHistories(data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    }
  };

  const handleChatClick = async (chatId: number, videoTitle: string) => {
    setActiveChatId(chatId); // Set the clicked chat as active
    setActiveVideoTitle(videoTitle); // Set the clicked chat's project as active
    try {
      const response = await fetch(`https://madai.app/chatfun/api/get_conversation?conversation_id=${chatId}`);
      const data = await response.json();

      {/* ""selectedChat"" is a state variable that stores 
      the details of the currently selected chat conversation. */} 
      if (data) {
        setSelectedChat({    
          ...data,
          videoTitle: data.name.split(' ')[0],
          video_id: data.video_id,
          video_filename: data.video_filename,
        });
      }
    } catch (error) {
      console.error('Error fetching chat conversation:', error);
    }
  };

  // Group the chat histories by project name
  const groupChatsByVideoTitle = (chats: Conversation[]) => {
    return chats.reduce((acc: { [key: string]: Conversation[] }, chat: Conversation) => {
      const videoTitle = chat.name.split(' ')[0];
      if (!acc[videoTitle]) {
        acc[videoTitle] = [];
      }
      acc[videoTitle].push(chat);
      return acc;
    }, {});
  };

  // Handle expanding or collapsing project chat lists
  const toggleVideoTitle = (videoTitle: string) => {
    setExpandedVideoTitle((prev) => ({
      ...prev,
      [videoTitle]: !prev[videoTitle],
    }));
  };

  useEffect(() => {
    if (userId) {
      fetchChatHistory();
    }
  }, [userId]);

  const groupedChats = groupChatsByVideoTitle(chatHistories);

  return (
    <div className="h-screen flex flex-col w-full">
      <div className="relative flex h-full w-full flex-1 overflow-hidden transition-colors z-0">
        <div className="relative flex h-full w-full flex-row overflow-hidden overflow-x-hidden ">
          {/* Side Bar */}
          <div
            className={`z-[21] flex-shrink-0 p-2 overflow-y-hidden overflow-x-hidden bg-token-sidebar-surface-primary max-md:!w-0  transition-all duration-300 ease-linear 
              "  ${expanded ? "w-[260px] bg-gray-100" : "w-15 bg-white"}`}
          >
            {/* <div
            className={`z-[21] flex-shrink-0 overflow-x-hidden  max-md:!w-0 transition-all duration-300 ease-linear `}
            style={expanded ? { width: '260px', visibility:'visible', willChange: 'auto', backgroundColor: 'deeppink'} : { width: '60px', visibility:'visible', willChange: 'auto'} }
          > */}
            <div className="h-full w-[260px]">
              <div className="flex h-full min-h-0 flex-col">
                <div className="draggable relative h-full w-full flex-1 items-start border-white/20">
                  <nav
                    className="flex h-full w-full flex-col px-3"
                    aria-label="chat-history"
                  >
                    {/* Top content of the Side Bar */}
                    <div className="flex justify-between h-[5%] items-center md:h-header-height">
                      <img
                        src={MadAI}
                        width={22}
                        height={40}
                        alt="madai"
                        className={`overflow-hidden transition-all ${
                          expanded ? "w-20" : "w-0"
                        }`}
                      />

                      <span className="flex">
                        <button
                          onClick={() => setExpanded((curr) => !curr)}
                          onMouseEnter={() => setIsTooltipVisible(true)}
                          onMouseLeave={() => setIsTooltipVisible(false)}
                          className={`flex flex-col p-1.5 rounded-lg bg-gray-100 hover:bg-gray-300 ${
                            expanded ? "" : " "
                          }`}
                        >
                          <BsLayoutSidebarInsetReverse />
                        </button>
                      </span>

                      {isTooltipVisible && (
                        <div
                          className={`absolute top-12  transform -translate-x-[10px] px-2 py-1 bg-black
                                     text-white text-sm rounded-md shadow-lg whitespace-nowrap ${
                                       expanded
                                         ? "md:left-[4%] lg:left-[70%] "
                                         : "md:left-[10%] lg:left-[20%] z-[20]"
                                     }`}
                        >
                          Sidebar
                        </div>
                      )}
                    </div>
                    {/* Close Top*/}

                    {/* Middle content of the Side Bar */}
                    <div
                      className="flex-col flex transition-opacity 
                                    duration-500 relative
                                    overflow-auto overflow-x-hidden h-[11%]"
                    >
                      <div className="group/slider">
                        <div>
                          <ul
                            className={` flex-3 px-4 p-3 ${
                              expanded ? "" : "opacity-0 w-0"
                            }`}
                          >
                            {SideBarCom.map((item) => {
                              const Icon: IconType = item.iconUrl;
                              return (
                                <li key={item.id}>
                                  <a
                                    href={item.url}
                                    onClick={() => handleItemClick(item.id)}
                                    className={`relative flex items-center py-2 px-7 my-1 gap-4 text-sm
                                                font-normal rounded-md cursor-pointer transition-colors
                    ${
                      active === item.id
                        ? "bg-black text-white"
                        : "hover:bg-gray-300 text-neutral-950"
                    }`}
                                  >
                                    <Icon className="mr-2" />
                                    <span
                                      className={`w-10 ml-3 ${
                                        expanded ? "" : "opacity-0"
                                      }`}
                                    >
                                      {item.title}
                                    </span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* Close Middle*/}

                    {/* Bottom content of the Side Bar */}
                    <div
                      className="flex flex-col gap-2 text-sm mt-5 
                        first:mt-0 h-[70%] p-4 overflow-auto"

                      id="chat-history"
                    >
                      <div>
                        <div className="">
                          <ol>
                            {Object.keys(groupedChats).length > 0 ? (
                              Object.keys(groupedChats).map((videoTitle) => (
                                <li key={videoTitle}>
                                  <div
                                    className={`py-2 rounded px-6 my-1 flex items-center gap-4 font-medium text-xs cursor-pointer
                        ${
                          expandedVideoTitle[videoTitle] ||
                          activeVideoTitle === videoTitle
                            ? "bg-gray-400 text-white"
                            : "bg-gray-200 hover:bg-gray-300"
                        }`}
                                    onClick={() => toggleVideoTitle(videoTitle)} // Toggle expand/collapse
                                  >
                                    {videoTitle}
                                  </div>
                                  {expandedVideoTitle[videoTitle] && (
                                    <ul className="pl-6">
                                      {groupedChats[videoTitle].map(
                                        (history) => (
                                          <li
                                            key={history.id}
                                            className={`py-1 rounded px-7 my-1 flex items-center gap-4 font-medium text-xs cursor-pointer
                              transition-colors ${
                                activeChatId === history.id
                                  ? "bg-gray-500 text-white"
                                  : "bg-gray-200 hover:bg-gray-300"
                              }`}
                                            onClick={() =>
                                              handleChatClick(
                                                history.id,
                                                videoTitle
                                              )
                                            } // Call handleChatClick on click
                                          >
                                            {history.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </li>
                              ))
                            ) : (
                              <li>No chat history found</li>
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col h-[100px] w-full"></div>
                  </nav>
                </div>
              </div>
            </div>

            {/* Close Bottom*/}
          </div>
          {/* </div> */}
          {/* Close Side Bar */}

          {/* Other Content */}
          <div className="relative flex h-full max-w-full flex-1 flex-col overflow-y-hidden">
            {/* "Outlet" from react-router-dom is used for nested routing, 
      allowing child components to access the context. */}
            <Outlet
              context={{
                fetchChatHistory,
                selectedChat,
                videoId: selectedChat?.video_id ?? uploadedVideoId,
                videoFileName: selectedChat?.video_filename ?? uploadedVideoId,
                videoTitle: selectedChat?.name.split(" ")[0] ?? uploadedVideoId,
              }}
            />
          </div>
        </div>
      </div>

      {/*Close Other Content */}
    </div> 
  );
};

export default Layout;

