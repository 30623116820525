import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'src/components/MadAI/layouts/Layout';
import ChatComponent from 'src/components/MadAI/ChatFun.components';
import { useUser } from 'src/hooks/useUser';

const MadaiRoutes: React.FC = () => {
  const { userid, videoId, projectName } = useUser();

  return (
      <Routes>
          <Route path="/" element={<Layout userId={userid} projectName={projectName} uploadedVideoId={videoId} />}>
              <Route 
                  index 
                  element={<ChatComponent userId={userid} projectName={projectName} uploadedVideoId={videoId}/>} 
              />
          </Route>
      </Routes>
  );
};

export default MadaiRoutes;
