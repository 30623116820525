import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import frame from "../../assets/valentine.png";

const quizzes = [
  [
    { id: "A", description: "Very comfortable—I can share anything without hesitation." },
    { id: "B", description: "Somewhat comfortable—I share most things, but hold back on some." },
    { id: "C", description: "Neutral—I share sometimes, but not always." },
    { id: "D", description: "Not very comfortable—I hesitate to open up." },
    { id: "E", description: "Not at all comfortable—I prefer to keep my thoughts private." },
  ],
  [
    { id: "A", description: "I would gladly make sacrifices for our future together." },
    { id: "B", description: "I would consider it seriously, but weigh my own needs too." },
    { id: "C", description: "I would be hesitant, but open to compromise." },
    { id: "D", description: "I would avoid making major sacrifices unless absolutely necessary." },
    { id: "E", description: "I don’t think I would make significant sacrifices for this relationship." },
  ],
  [
    { id: "A", description: "Very happy—I love physical affection from them." },
    { id: "B", description: "Happy—I enjoy it but don’t need it all the time." },
    { id: "C", description: "Neutral—I don’t mind it, but I don’t crave it." },
    { id: "D", description: "Uncomfortable—I don’t enjoy too much physical affection." },
    { id: "E", description: "Uninterested—I don’t feel anything special about it." },
  ],
];

const titles = [
  "1. How comfortable do you feel sharing your personal thoughts and fears with your partner?",
  "2. How do you feel about making sacrifices for your partner if needed (e.g., career changes, relocating, adjusting lifestyle)?",
  "3. How do you feel when your partner expresses love through physical touch (hugs, kisses, holding hands, etc.)?",
];

const QuizSelection: React.FC = () => {
  const navigate = useNavigate();
  const [showIntro, setShowIntro] = useState(true); // State to control the intro text
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userResponses, setUserResponses] = useState<string[]>([]);

  // Hide the intro text after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleAnswerClick = (answerId: string) => {
    const updatedResponses = [...userResponses, answerId];

    if (currentIndex < quizzes.length - 1) {
      setUserResponses(updatedResponses);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      navigate("/love-stage", { state: { loveType: determineLoveType(updatedResponses) } });
    }
  };

  const determineLoveType = (responses: string[]) => {
    const [first, second, third] = responses;

    if (["A", "B", "C"].includes(first)) {
      if (["A", "B", "C"].includes(second)) {
        if (["A", "B", "C"].includes(third)) {
          return "True Love";
        } else {
          return "Deep Bond";
        }
      } else if (["D", "E"].includes(second)) {
        return ["A", "B", "C"].includes(third) ? "Romantic Love" : "Friendship Love";
      }
    } else if (["D", "E"].includes(first)) {
      if (["A", "B", "C"].includes(second)) {
        return ["A", "B", "C"].includes(third) ? "Instant Love" : "Empty Love";
      } else if (["D", "E"].includes(second)) {
        return ["A", "B", "C"].includes(third) ? "Crush" : "No Relationship";
      }
    }

    return "Go to love triangle";
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-5 sm:p-10 relative"
      style={{
        backgroundImage: `url(${frame})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Animated Intro Text - Only Shows for 5 Seconds */}
      {showIntro ? (
        <div className="absolute top-1/3 flex flex-col items-center animate-fadeInOut bg-pink-50 py-10 px-20">
          <h1 className="text-3xl sm:text-3xl md:text-4xl font-bold text-center text-red-500 ">
            Decode Your Love:
          </h1>
          <h2 className="text-3xl sm:text-2xl md:text-3xl font-bold text-center text-red-600">
            Uncover Your Love Stage!
          </h2>
        </div>
      ) : (
        // Quiz UI - Displays After 5 Seconds
        <div className="w-full max-w-md sm:w-3/4 md:w-2/3 lg:w-1/2 p-6 rounded-xl bg-pink-50 shadow-lg">
          {/* Title Section */}
          <h1 className="text-base sm:text-lg md:text-xl font-bold text-left mb-6">
            {titles[currentIndex]}
          </h1>

          {/* Quiz Options */}
          <div className="flex flex-col items-center w-full">
            {quizzes[currentIndex].map((quiz) => (
              <div
                key={quiz.id}
                className="bg-white w-full sm:w-4/5 md:w-3/4 h-15 flex items-center rounded-full 
                p-3 sm:p-4 mb-4 shadow-md cursor-pointer hover:bg-gray-200 transition duration-200"
                onClick={() => handleAnswerClick(quiz.id)}
              >
                {/* Option Circle */}
                <div className="border-2 fixed border-deep-orange-900 flex justify-center items-center rounded-full 
                w-10 h-10 bg-blue-gray-100 text-sm font-bold text-black">
                  {quiz.id}
                </div>

                {/* Option Description */}
                <span className="text-xs sm:text-sm md:text-md lg:text-lg text-black ml-12">
                  {quiz.description}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizSelection;
