import React from "react";
import Radar from "../Graphs/Radar";

const PolarCard: React.FC = () => {
  return (
    <div
      className="cursor-pointer group flex relative items-center 
    justify-center my-6 bg-gray-300 shadow-sm border border-slate-200 
    rounded-lg w-auto hover:shadow-lg transition-shadow duration-300"
    >
      <div
        className="relative h-60 m-2.5 overflow-hidden text-white 
      rounded-md"
      >
        {/* Image section */}
        <div className="relative ">
          <Radar />
        </div>
        {/* Sliding Text Section */}
        <div
          className="absolute bottom-0 left-0 w-full bg-black p-4 
        text-slate-800 transform translate-y-full group-hover:translate-y-20 
        transition-transform duration-300 ease-out"
        >
          <p className="text-sm mb-16 p-2 text-white">
            Here you can see how emotions of the video are changing with the
            time of the video .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PolarCard;

