import React, { useState, useRef, forwardRef } from "react";
import { PolarArea } from "react-chartjs-2";
import { FaDownload} from "react-icons/fa";
import html2canvas from "html2canvas"

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  ChartOptions,
} from "chart.js";

import { useEmotionData } from "../../../context/EmotionDataContext";

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale);

interface EmotionData {
  [emotion: string]: {
    [second: string]: number;
  };
}

const emotionColors: { [key: string]: string } = {
    "Astonishment ": "#800080",
  "Disgust ": "#A52A2A",
  "Fear ": "#000000",
  "Joy ": "#9ACD32",
  "Sorrow ": "#87CEEB",
  "Anger ": "#FF0000",
  "Peace ": "#0000FF",
  "Love ": "#FFC0CB",
  "Confidence ": "#FFD700",
};

const PolarAreaChart = forwardRef<HTMLDivElement>((props, ref) => {
  const radarRef = useRef<HTMLDivElement>(null);
  const { emotionData }: { emotionData: EmotionData | null } = useEmotionData();
  const [selectedSeconds, setSelectedSeconds] = useState<string[]>(["all"]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  if (!emotionData) {
    return <p>No emotion data available.</p>;
  }

  const handleDownload = async () => {
	if (radarRef.current) {
		const canvas = await html2canvas(radarRef.current);
		const image = canvas.toDataURL("image/png");
		const link = document.createElement("a");
		link.href = image;
		link.download = "radar.png";
		link.click();
	}
};
  const seconds = Object.keys(emotionData[Object.keys(emotionData)[0]]);

  // Toggle selection for a specific second
  const toggleSecond = (second: string) => {
    if (selectedSeconds.includes("all")) {
      setSelectedSeconds([second]);
    } else if (selectedSeconds.includes(second)) {
      setSelectedSeconds(selectedSeconds.filter((s) => s !== second));
    } else {
      setSelectedSeconds([...selectedSeconds, second]);
    }
  };

  // Toggle "Select All" option
  const toggleSelectAll = () => {
    setSelectedSeconds(["all"]);
  };

  // Prepare chart labels and datasets
  let chartLabels: string[] = [];
  let chartDatasets: any[] = [];

  if (selectedSeconds.includes("all") || selectedSeconds.length === 0) {
    // "Select All" case or no seconds selected
    chartLabels = seconds;
    chartDatasets = Object.keys(emotionData).map((emotion) => ({
      label: emotion,
      data: seconds.map((second) => emotionData[emotion][second] || 0),
      backgroundColor: emotionColors[emotion],
    }));
  } else {
    // Specific seconds selected
    chartLabels = Object.keys(emotionData);
    chartDatasets = [
      {
        label: `Emotions for selected seconds`,
        data: chartLabels.map((emotion) =>
          selectedSeconds.reduce(
            (sum, second) => sum + (emotionData[emotion][second] || 0),
            0
          )
        ),
        backgroundColor: chartLabels.map((emotion) => emotionColors[emotion]),
      },
    ];
  }

  const chartData = {
    labels: chartLabels,
    datasets: chartDatasets,
  };

const options: ChartOptions<"polarArea"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          generateLabels: (chart) => {
            if (
              chart.data.datasets &&
              chart.data.datasets[0] &&
              Array.isArray(chart.data.datasets[0].backgroundColor)
            ) {
              return chart.data.labels!.map((label, index) => ({
                text: label as string,
                fillStyle: (chart.data.datasets[0].backgroundColor as string[])[
                  index
                ],
              }));
            }
            return [];
          },
          font: {
            size: 14,
          },
          color: "black",
        },
      },
    },
    scales: {
      r: {
        ticks: {
          display: false,
          color: "black",
          backdropColor: "black",
        },
        grid: {
          color: "black",
        },
        pointLabels: {
          display: true, // Always display the labels
          callback: (value, index) =>
            selectedSeconds.includes("all") ? seconds[index] + "s" : "", // Show seconds only when "Select All" is active
          font: {
            size: 14,
          },
          color: "black",
        },
      },
    },
  };


  return (
    <div className="">
      <div className="flex relative h-[40rem] w-[55rem] mt-6" ref={radarRef}>
        <div className="absolute right-10">
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className="p-2 text-black rounded border-4
           border-gray-800 focus:outline-none left-0"
          >
            {selectedSeconds.includes("all")
              ? "Select All"
              : `Selected: ${selectedSeconds.join(", ")}s`}
          </button>
          {dropdownOpen && (
            <div
              className="absolute bg-n-2 text-black mt-2 rounded shadow-lg 
          z-10 overflow-scroll w-40 h-40"
            >
              <div
                className="flex flex-col space-y-2 p-2  overflow-y-auto"
                style={{
                  scrollbarWidth: "thin", // For Firefox
                }}
              >
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="time-range"
                    value="all"
                    checked={selectedSeconds.includes("all")}
                    onChange={toggleSelectAll}
                    className="mr-2"
                  />
                  Select All
                </label>
                {seconds.map((second) => (
                  <label key={second} className="flex items-center">
                    <input
                      type="checkbox"
                      name="time-range"
                      value={second}
                      checked={selectedSeconds.includes(second)}
                      onChange={() => toggleSecond(second)}
                      className="mr-2"
                    />
                    {second}s
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>

        <PolarArea
          data={chartData}
          options={options}
          className="bg-n-2 h-auto rounded-md p-4"
        />
        <button
          className="absolute top-0 left-[55.5rem] bg-blue-500 text-white rounded-full
              p-2 hover:bg-blue-700 transition-all duration-200"
          onClick={handleDownload}
        >
          <FaDownload />
        </button>
      </div>
    </div>
         
  );
});

export default PolarAreaChart;
