import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Home/Header.component';
//import Hero from './components/Home/Hero.component';
//import Why from './components/Home/Features';
//import HowtoUse from './components/Home/HowtoUse.component';
//import Pricing from './components/Home/Pricing.components';
//import Layout from './components/MadAI/layouts/Layout';
//import ChatBox from './components/MadAI/ChatBox.components';
//import ChatComponent from './components/MadAI/ChatFun.components';
import SignInUp from './components/Authentication/SignInUp.components';
//import { SignInMethod } from 'firebase/auth';
import HomeRoutes from './routes/HomeRoutes';
import MadaiRoutes from './routes/MadaiRoutes';
import ToothColorDetection from './components/Teeth/Teeth';
import Result from './components/Teeth/Result';
import Form from './components/GSK/Form';
import ProductDetail from './components/GSK/ProductDetail';

function App() {
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  return (

    // If isHomePage is true, the classes 'pt-[9.75rem] lg:pt-[8.75rem]' will be applied to the div element.
    <div className={isHomePage ? 'pt-[9.75rem] lg:pt-[8.75rem]' : ""}>
      {/* If isHomePage is true, the Header component will be rendered  */}
      {isHomePage && <Header />}
      <Routes>
        <Route path="/" element={<HomeRoutes/>} />

        <Route path="/madai/*" element={<MadaiRoutes/>}/>

        <Route path='/login' element={<SignInUp/>}/>
        <Route path="/teeth" element={<ToothColorDetection/>} />
        <Route path="/result" element={<Result/>} />
        <Route path="/gsk-form" element={<Form/>} />
        <Route path="/product-details" element={<ProductDetail/>} />
      </Routes>
    </div>

  );
};

export default App;
