import React, {useRef, forwardRef} from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useEmotionData } from "../../../context/EmotionDataContext"; // Adjust path as per your project structure
import { FaDownload } from "react-icons/fa";
import html2canvas from "html2canvas";

const emotionColors: { [key: string]: string } = {
   "Astonishment ": "#800080",
  "Disgust ": "#A52A2A",
  "Fear ": "#000000",
  "Joy ": "#9ACD32",
  "Sorrow ": "#87CEEB",
  "Anger ": "#FF0000",
  "Peace ": "#0000FF",
  "Love ": "#FFC0CB",
  "Confidence ": "#FFD700",
};

const EmotionBarChart = forwardRef<HTMLDivElement>((props, ref) => {
  const { emotionData } = useEmotionData();
  const emotionref = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    if (emotionref.current) {
      const canvas = await html2canvas(emotionref.current);
      const image = canvas.toDataURL("image/url");
      const link = document.createElement("a");
      link.href = image;
      link.download = "emotion.png";
      link.click();
    }
  };

  if (!emotionData) {
    return <div className=" flex flex-col items-center justify-center bg-n-2 p-4 rounded-lg shadow-lg
	 text-white">
		No emotion data available
	</div>;
  }

  // Calculate the sum for each emotion
  const emotions = Object.keys(emotionColors); // Labels for x-axis
  const emotionSums = emotions.map((emotion) => {
    const emotionValues = Object.values(emotionData[emotion] || {});
    return emotionValues.reduce((sum, value) => sum + value, 0); // Sum all values
  });

  return (
    <div className="">
      <div className="flex relative h-[40rem] w-[55rem] mt-6" ref={emotionref}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: emotions, // Map emotions as x-axis labels
              label: "Emotions", // Label for the x-axis
              tickFontSize: 14, // Customize tick font size
            },
          ]}
          yAxis={[
            {
              label: "Emotion Scores", // Label for the y-axis
              tickFontSize: 14, // Customize tick font size
              tickSize: 8,
            },
          ]}
          series={[
            {
              data: emotionSums, // Array of numbers for the Y-axis
            },
          ]}
          width={900}
          height={630}
          sx={{
            "& .MuiChartAxis-root .MuiChartAxis-tickLabel": {
              fill: "white", // Axis tick labels color
            },
            "& .MuiChartAxis-root .MuiChartAxis-label": {
              fill: "white", // Axis main label color
            },
            "& .MuiChartAxis-root .MuiChartAxis-tickLine": {
              stroke: "white", // Axis ticks color
            },
            "& .MuiChartAxis-root .MuiChartAxis-line": {
              stroke: "white", // Axis lines color
            },
          }}
          className="w-full relative items-center justify-center h-auto
                     bg-n-2 rounded-md p-4 mt-0"
        />
        <button
          className="absolute top-0 left-[55.5rem] bg-blue-500 text-white rounded-full
              p-2 hover:bg-blue-700 transition-all duration-200"
          onClick={handleDownload}
        >
          <FaDownload />
        </button>
      </div>
      //
    </div>
  );
});

export default EmotionBarChart;
