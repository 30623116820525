import React from 'react';
import {Routes, Route} from 'react-router-dom'
import Hero from 'src/components/Home/Hero.component';
import Why from 'src/components/Home/Features';
import HowtoUse from 'src/components/Home/HowtoUse.component';
import Pricing from 'src/components/Home/Pricing.components';


function HomeRoutes() {
  return (
    <Routes>
        <Route>
        <Route path="/" element={
          <>
            <Hero />
            <Why />
            <HowtoUse />
            <Pricing />
          </>
        } />
        </Route>
    </Routes>
  )
}

export default HomeRoutes;
