import React, { useState, useEffect } from 'react';
//import ChatComponent from './ChatFun.components';
import MadaiForm from './MadaiForm.components';
import { IoBarChartSharp } from "react-icons/io5";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import Graph from './Graph.components';
import Video from './Video.components';
import { useNavigate } from 'react-router-dom'; // For redirection

interface ChatBoxMainprops {
  children?: React.ReactNode;
  className?: string;
}

const ChatBox: React.FC<ChatBoxMainprops> = ({ children, className}) => {
  const [activeSection, setActiveSection] = useState<'form' | 'graph' | null>('form');
  const [videoFilename,  setVideoFilename] = useState<string | null>(null);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [isTooltipVisibleGraph, setIsTooltipVisibleGraph] = useState(false);
  const [isTooltipVisibleFile, setIsTooltipVisibleFile] = useState(false);
  const [isTokenExpiredPopupVisible, setIsTokenExpiredPopupVisible] = useState(false); // New state for pop-up visibility

  const navigate = useNavigate(); // React Router's navigate hook for redirection

  const handleShowForm = () => setActiveSection('form');
  const handleShowGraph = () => setActiveSection('graph');
  const handleVideoUpload = (filename: string) => setVideoFilename(filename);

  useEffect(() => {
    // Retrieve the video filename from localStorage on component mount
    const storedVideoFilename = localStorage.getItem('uploadedVideoFilename');
    const storedVideoId = localStorage.getItem('videoId');
    if (storedVideoFilename) {
      setVideoFilename(storedVideoFilename);
    }
    if (storedVideoId) {
      setVideoId(storedVideoId);
    }

    // Check if the token is expired or missing
    const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
    if (!token || isTokenExpired(token)) {
      setIsTokenExpiredPopupVisible(true); // Show pop-up if token is expired
    }
  }, []);

  // Function to check if the token is expired
  const isTokenExpired = (token: string): boolean => {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode the JWT token
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp < currentTime; // Check if token is expired
    } catch (error) {
      console.error("Failed to decode token:", error);
      return true; // Treat as expired if decoding fails
    }
  };

  // Function to navigate to the login page
  const navigateToLogin = () => {
    navigate('/login');
  };


  return (
    <div className='flex flex-row h-screen overflow-hidden'>
      <div className='h-full w-3/4 bg-white p-2'>  {/* Chat section*/}

        <div className="flex flex-col p-2  justify-center items-center font-bold h-[5%]">
          <h4 className='text-[1.5rem] uppercase font-[4rem]'>Mad<span className='text-red-800'>AI</span> </h4>
        </div>
        <div className={`flex-row h-[95%] bg ${className || ""}`}>{children}</div>
          {/* <div className="flex p-2 bg-slate-500 h-[14%]">input section</div> */}
{/* 
          <div>
            <ChatComponent userId="exampleUserId" projectName="exampleProject" uploadedVideoId="exampleVideoId" />
          </div> */}

      </div>

      <div className='flex-col w-1/4 bg-gray-100 p-2'> {/* Right side section*/}

         <div className="flex flex-col h-[25%]">
          {videoId ? <Video videoId={videoId} /> : <p>No video available</p>}
        </div>
        <div className='flex p-2 h-[8%]'>
          <div className='absolute right-4 px-2 py-1 hover:bg-gray-200 rounded-md ' >          
            <button
              className='relative pt-1'
              onMouseEnter={() => setIsTooltipVisibleFile(true)}
              onMouseLeave={() => setIsTooltipVisibleFile(false)}
              onClick={handleShowForm}>
              <MdOutlineDriveFolderUpload size={20} />
            </button>
            {isTooltipVisibleFile && (
              <div className="absolute bottom-2 right-[100%] transform -translate-x-[10px] px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap">
                File Upload
              </div>
            )}
          </div>

          <div className='absolute  px-2 py-1 hover:bg-gray-200 rounded-md '>          
            <button
              className='relative pt-1'
              onMouseEnter={() => setIsTooltipVisibleGraph(true)}
              onMouseLeave={() => setIsTooltipVisibleGraph(false)}
              onClick={handleShowGraph}>
              <IoBarChartSharp size={20} />
            </button>
            {isTooltipVisibleGraph && (
              <div className="absolute bottom-2 right-[100%] transform -translate-x-[10px] px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap">
                Graphs
              </div>
            )}

          </div>
        </div>

        <div className="flex flex-col h-[60%] bg-neutral-500"> {/* Graph & form section*/}
          <div className=' bg-white h-full rounded-md'>
             {/* Render both form and graph but toggle visibility */}
            <div style={{ display: activeSection === 'form' ? 'block' : 'none' }}>
              <MadaiForm onVideoUpload={handleVideoUpload} />
            </div>
            <div style={{ display: activeSection === 'graph' ? 'block' : 'none' }}>
              {videoId && <Graph videoId={videoId} />}
            </div>
          </div>
        </div>

      </div>
            {/* Pop-up card when token expires */}
      {isTokenExpiredPopupVisible && (
        <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Session Expired</h2>
            <p className="mb-4">Please log in again to continue.</p>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={navigateToLogin}
            >
              Login
            </button>
          </div>
        </div>
      )}

    </div>
  )
}

export default ChatBox;
