import Section from "./Section.component";
import Heading from "./Heading";
import PricingList from "./PricingList";
import { LeftLine, RightLine } from "./design/Pricing";

const Pricing = () => {
  return (
    <Section className="overflow-hidden" id="pricing">
      <div className="container-price-com relative z-2">
        {/* <div className="hidden relative justify-center mb-[6.25rem] lg:flex">

                <img className='relative z-1' src={smallSphere} width={255} height={255} alt="Sphere" />

                <div className='absolute top-1/2 left-1/2 w-[60rem] -translate-x-1/2 -translate-y-1/2 pointer-events-none' >
                    <img src={stars} className='w-full' width={950} height={400} alt="star" />
                </div>
            </div> */}
        {/* Choose the right plan for you */}
        <Heading
          tag="Get Started with MAdAI"
          title="Request for the Demo"
          className="mt-5"
        />

        <div className="flex bg-white p-10 items-center justify-center ">
          <PricingList />
          {/* <LeftLine/> */}
          <RightLine />
        </div>
      </div>
    </Section>
  );
};

export default Pricing;

