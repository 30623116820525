import React, { useEffect, useState, useRef } from 'react';
import ChatBox from './ChatBox.components';
import { FaCircleArrowRight, FaPenToSquare } from "react-icons/fa6";
import '../../styles/madai.css';
import { useOutletContext } from 'react-router-dom';

interface ChatProps {
  userId: string;
  uploadedVideoId?: string;
  projectName: string;
}

interface ChatMessage {
  role: 'user' | 'bot';
  message: string;
  loading?: boolean;
}

interface SelectedChat {
  id: number;
  name: string;
  video_id: number;
  data: string;  // The messages are stored as a JSON string
  timestamp: string;
}

const ChatComponent: React.FC<ChatProps> = ({ userId, uploadedVideoId, projectName }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]); // State to store chat messages
  const [inputMessage, setInputMessage] = useState<string>(''); // State to store user input
  const [loading, setLoading] = useState<boolean>(false);
  const [chatCount, setChatCount] = useState<number>(1); // Track chat count
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  // Get the selectedChat and fetchChatHistory from Layout via OutletContext
  const { selectedChat, fetchChatHistory } = useOutletContext<{ selectedChat: SelectedChat | null; fetchChatHistory: () => void }>();
  const chatDisplayRef = useRef<HTMLDivElement>(null); // Ref for the chat display section
    // Log selectedChat to check if it's correctly passed
    useEffect(() => {
      console.log('Selected Chat:', selectedChat);
    }, [selectedChat]);

  // Update the messages when a new chat is selected
  useEffect(() => {
    if (selectedChat && selectedChat.data) {
      try {
        // Parse the JSON string to get the messages array
        const parsedMessages: ChatMessage[] = JSON.parse(selectedChat.data);
        setMessages(parsedMessages);  // Set the parsed messages in the state
      } catch (error) {
        console.error("Error parsing chat messages:", error);
      }
    }
  }, [selectedChat]);

  // Scroll to the bottom of the chat display and center the last message
  useEffect(() => {
    if (chatDisplayRef.current) {
      const chatDisplay = chatDisplayRef.current;
      const lastMessage = chatDisplay.lastElementChild as HTMLElement | null; // Typecast to HTMLElement
  
      if (lastMessage) {
        const offsetTop = lastMessage.offsetTop;
        const containerHeight = chatDisplay.clientHeight;
        const messageHeight = lastMessage.clientHeight;
        const scrollTop = offsetTop - (containerHeight / 2) + (messageHeight / 2);
  
        chatDisplay.scrollTo({
          top: scrollTop,
          behavior: 'smooth', // Smooth scrolling
        });
      }
    }
  }, [messages]);
  

  // Function to generate conversation name
  const generateConversationName = (): string => {
    return `${projectName} chat-${chatCount}`; // Generate conversation name using project name and chat count
  };

  // Function to save the current conversation if there are any messages
  const saveConversation = async () => {
    if (messages.length === 0) return; // Do not save if chat messages list is empty

    try {
      const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
      if (token) {
        // Remove the 'loading' property from any 'bot' message
        const sanitizedMessages = messages.map(message => {
          if (message.role === 'bot') {
            const { loading, ...sanitizedMessage } = message; // Exclude 'loading' property
            return sanitizedMessage;
          }
          return message;
        });

        const conversationData = JSON.stringify(sanitizedMessages);
        const conversationName = generateConversationName();

        const response = await fetch('https://madai.app/chatfun/api/save_conversation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${JSON.parse(token)}`,
          },
          body: JSON.stringify({
            conversation_data: conversationData,
            video_id: selectedChat?.video_id ?? uploadedVideoId, // Send selectedChat's video ID or the uploadedVideoId
            project_name: selectedChat?.name.split(' ')[0] ?? projectName, // Send selectedChat's projectName or the passed projectName
            conversation_name: conversationName,
          }),
          
        });

        if (!response.ok) {
          console.error('Failed to save conversation');
        } else {
          console.log('Conversation saved successfully');
        }
      }
    } catch (error) {
      console.error('Error saving conversation:', error);
    }
  };

  // Handle form submission (but no call to saveConversation here)
  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (inputMessage.trim() === '') return;

    const newMessage: ChatMessage = { role: 'user', message: inputMessage };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    const placeholderMessage: ChatMessage = { role: 'bot', message: '', loading: true };
    setMessages(prevMessages => [...prevMessages, placeholderMessage]);

    setInputMessage(''); // Clear the input field
    setLoading(true);

    // Call the backend API to get the bot response
    try {
      const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
      if (token) {
        const response = await fetch('https://madai.app/chatfun/api/chatbot', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${JSON.parse(token)}`,
          },
          body: JSON.stringify({
            message: newMessage.message,
            video_id: selectedChat?.video_id ?? uploadedVideoId, // Send selectedChat's video ID or uploadedVideoId
            project_name: selectedChat?.name.split(' ')[0] ?? projectName, // Send selectedChat's projectName or projectName from props

          }),
        });

        const data = await response.json();

        const updatedBotMessage = { ...placeholderMessage, message: data.bot_response, loading: false };
        const finalMessages = [...updatedMessages, updatedBotMessage];
        setMessages(finalMessages);
      }
    } catch (error) {
      console.error('Error fetching bot response:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle "Enter" key press to submit the form
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleFormSubmit(event as unknown as React.FormEvent);
    }
  };

  // Save conversation when the tab is closed or refreshed
  useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      event.preventDefault();
      await saveConversation();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [messages]); // Ensure the latest messages are saved

  // // Scroll to the bottom of the chat display whenever messages are updated
  useEffect(() => {
     const chatDisplay = document.getElementById('chat-display');
     if (chatDisplay) {
       chatDisplay.scrollTop = chatDisplay.scrollHeight;
     }
   }, [messages]);

  return (
    <ChatBox >
      <div className="p-16 h-[85%] pt-8 pb-5 bg-white overflow-y-auto" id="chat-display">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role === 'user' ? 'user-message' : 'bot-message'}`}>
            <span className={`${msg.role === 'user' ? 'user-prefix' : 'bot-prefix'}`}>
              {msg.role === 'user' ? '' : 'MadAI:'}
            </span>
            {msg.message}
            {msg.role === 'bot' && index === messages.length - 1 && loading && (
              <div className="spinner"></div>
            )}
          </div>
        ))}
      </div>
      <div className="chat-message flex h-[15%] p-2 justify-center items-center">
        <form id="chat-form" onSubmit={handleFormSubmit}>
          <div className="relative">
            <button
              type="button"
              onMouseEnter={() => setIsTooltipVisible(true)}
              onMouseLeave={() => setIsTooltipVisible(false)}
              onClick={async () => {
                await saveConversation(); // Save the current conversation only if it's not empty
                setMessages([]); // Clear messages to start a new chat
                setChatCount(prevCount => prevCount + 1); // Increment chat count
                fetchChatHistory(); // Refresh chat history
              }}
              className="absolute left-4 top-[44%] transform -translate-y-1/2"
            >
              <FaPenToSquare size={18} className="text-black hover:text-gray-700 transition-colors duration-200" />
            </button>
            {isTooltipVisible && (
              <div className="absolute bottom-12 left-[3.5%] transform -translate-x-1/2 px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap">
                New Chat
              </div>
            )}
            <textarea
              className="rounded-full sm:w-[20rem] md:w-[30rem] lg:w-[40rem] resize-none h-[3rem] p-3 text-sm top-2 pl-[4rem] pr-[8rem] pb-[0rem] pt-[1rem] scrollbar-none bg-gray-100 outline-none"
              name="message"
              id="chat-input"
              placeholder="Message with MadAI..."
              rows={3}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            ></textarea>
            <button type="submit" id="send-button" className="absolute right-4 top-[44%] transform -translate-y-1/2">
              <FaCircleArrowRight size={24} className="text-black hover:text-gray-700 transition-colors duration-200" />
            </button>
          </div>
        </form>
      </div>
    </ChatBox>
  );
};

export default ChatComponent;

