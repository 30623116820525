import React, { useEffect, useState, useRef } from "react";
import ChatBox from "./ChatBox.components";
import { FaCircleArrowRight, FaPenToSquare } from "react-icons/fa6";
import "../../styles/madai.css";
import { useOutletContext } from "react-router-dom";

// Interfaces for props and state
interface ChatProps {
  userId: string;
  uploadedVideoId?: string;
  videoTitle: string;
}

interface ChatMessage {
  role: "user" | "bot";
  message: string;
  structuredContent?: any; // Structured content can be any object
  loading?: boolean;
}

interface SelectedChat {
  id: number;
  name: string;
  video_id: number;
  data: string;
  timestamp: string;
}

const ChatComponent: React.FC<ChatProps> = ({
  userId,
  uploadedVideoId,
  videoTitle,
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [chatCount, setChatCount] = useState<number>(1);

  const previousVideoTitleRef = useRef<string | null>(null);
  const previousUserIdRef = useRef<string | null>(null);
  const chatDisplayRef = useRef<HTMLDivElement>(null);

  const { selectedChat, fetchChatHistory } = useOutletContext<{
    selectedChat: SelectedChat | null;
    fetchChatHistory: () => void;
  }>();

  // Load chat history
  useEffect(() => {
    if (selectedChat?.data) {
      try {
        const parsedMessages: ChatMessage[] = JSON.parse(selectedChat.data);
        setMessages(parsedMessages);
      } catch (error) {
        console.error("Error parsing chat messages:", error);
      }
    }
  }, [selectedChat]);

  // Auto-scroll to the latest message
  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  const saveConversation = async ( isNewChat: boolean = false ) => {
    if (messages.length === 0) return;

    try {
      const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
      if (token) {
        const sanitizedMessages = messages.map(({ loading, ...msg }) => msg);
        const response = await fetch(
          'https://madai.app/chatfun/api/save_conversation',
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
            body: JSON.stringify({
              conversation_data: JSON.stringify(sanitizedMessages),
              video_id: selectedChat?.video_id ?? uploadedVideoId,
              video_title: selectedChat?.name.split(" ")[0] ?? videoTitle,
              is_new_chat: isNewChat, // Pass the flag to backend
            }),
          }
        );

        if (!response.ok) {
          console.error("Failed to save conversation");
        } else {
          console.log("Conversation saved successfully");
        }
      }
    } catch (error) {
      console.error("Error saving conversation:", error);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!inputMessage.trim()) return;

    const newMessage: ChatMessage = { role: "user", message: inputMessage };
    setMessages((prev) => [...prev, newMessage]);

    const placeholderMessage: ChatMessage = {
      role: "bot",
      message: "",
      loading: true,
    };
    setMessages((prev) => [...prev, placeholderMessage]);

    setInputMessage("");
    setLoading(true);

    try {
      const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
      if (token) {
        const response = await fetch('https://madai.app/chatfun/api/chatbot', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
          body: JSON.stringify({
            message: inputMessage,
            video_id: selectedChat?.video_id ?? uploadedVideoId,
            video_title: selectedChat?.name.split(" ")[0] ?? videoTitle,
          }),
        });

        const data = await response.json();
        const botResponse = data.bot_response;

        const updatedBotMessage: ChatMessage = {
          role: "bot",
          message: typeof botResponse === "string" ? botResponse : "",
          structuredContent:
            typeof botResponse === "object" ? botResponse : undefined,
          loading: false,
        };

        setMessages((prev) => {
          const newMessages = [...prev];
          newMessages[newMessages.length - 1] = updatedBotMessage;
          return newMessages;
        });

        await saveConversation();
      }
    } catch (error) {
      console.error("Error fetching bot response:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewChat = async () => {
    await saveConversation(true); // Save current chat and indicate it's a new chat
    setMessages([]); // Clear messages to start a new chat
    setChatCount((prevCount) => {
      const newCount = prevCount + 1;
      localStorage.setItem(`chatCount-${videoTitle}`, newCount.toString());
      return newCount;
    });
    fetchChatHistory(); // Refresh chat history
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleFormSubmit(event as unknown as React.FormEvent);
    }
  };

  const renderStructuredContent = (structuredContent: unknown): React.ReactNode => {
    if (structuredContent === null) return null;
    if (typeof structuredContent === "string" || typeof structuredContent === "number") {
      return <p className="ml-3">{structuredContent}</p>;
    }

    if (Array.isArray(structuredContent)) {
      return (
        <ul className="list-disc ml-5 bg-gray-100 p-4 rounded-md border-black border-opacity-5 border-2">
          {structuredContent.map((item, idx) => (
            <li className="ml-4" key={idx}>
              {renderStructuredContent(item)}
            </li>
          ))}
        </ul>
      );
    }

    if (typeof structuredContent === "object") {
      return (
        <div className="structured-content p-6">
          {Object.entries(structuredContent).map(([key, value]) => {
            if (["Headings"].includes(key)) return null;
            if (key === "List" || key === "Introduction" || key === "Madai_response") {
              return <div key={key}>{renderStructuredContent(value)}</div>;
            }
            return (
              <div key={key} className="mb-3">
                <h1 className="font-semibold text-xl capitalize">
                  {key.replace(/([A-Z])/g, " $1")}:
                </h1>
                {renderStructuredContent(value)}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <ChatBox>
      <div
        className="p-16 h-[85%] pt-8 pb-5 bg-white overflow-y-auto flex flex-col"
        id="chat-display"
        ref={chatDisplayRef}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${
              msg.role === "user" ? "user-message" : "bot-message"
            }`}
            style={{ margin: "10px 0" }}
          >
            {msg.role === "bot" && msg.loading ? (
              <div className="flex items-center">
                <div className="spinner mr-2"></div>
              </div>
            ) : msg.role === "bot" && msg.structuredContent ? (
              renderStructuredContent(msg.structuredContent)
            ) : (
              <span>{msg.message}</span>
            )}
          </div>
        ))}
      </div>
      <div
        className=""
      >
        <div>
          <div className="m-auto text-base px-3 w-full md:px-5 lg:px-4 xl:px-5">
            <div
              className="mx-auto flex flex-1 text-base gap-4 md:gap-5 
                          lg:gap-6 md:max-w-3xl 
                          lg:max-w-[40rem] xl:max-w-[48rem]"
            >
              {/* <div className="flex justify-center empty:hidden"></div> */}

              <div className="chat-message flex h-[15%] p-2 justify-center items-center">
                <form id="chat-form w-full" onSubmit={handleFormSubmit}>
                  {/* <div className="relative z-[1] flex h-full max-w-full flex-1 flex-col">
                    <div className="absolute bottom-full left-0 right-0 z-20"></div>
                    <div className="group relative z-[1] flex w-full items-center"> */}
                      <div className="w-full">
                        <div className="relative">
                          <button
                            type="button"
                            onMouseEnter={() => setIsTooltipVisible(true)}
                            onMouseLeave={() => setIsTooltipVisible(false)}
                            onClick={handleNewChat}
                            className="absolute left-4 top-[44%] transform -translate-y-1/2"
                          >
                            <FaPenToSquare
                              size={18}
                              className="text-black hover:text-gray-700 transition-colors duration-200"
                            />
                          </button>
                          {isTooltipVisible && (
                            <div className="absolute bottom-12 left-[3.5%] transform -translate-x-1/2 px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap">
                              New Chat
                            </div>
                          )}
                          <textarea
                            className="rounded-full sm:w-[20rem] md:w-[30rem] lg:w-[40rem] resize-none h-[3rem] p-3 text-sm top-2 pl-[4rem] pr-[8rem] pb-[0rem] pt-[1rem] scrollbar-none bg-gray-100 outline-none"
                            name="message"
                            id="chat-input"
                            placeholder="Message with MadAI..."
                            rows={3}
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                          ></textarea>
                          <button
                            type="submit"
                            id="send-button"
                            className="absolute right-4 top-[44%] transform -translate-y-1/2"
                          >
                            <FaCircleArrowRight
                              size={24}
                              className="text-black hover:text-gray-700 transition-colors duration-200"
                            />
                          </button>
                        </div>
                      </div>
                    {/* </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>     
    </ChatBox>
  );
};

export default ChatComponent;

