import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the hook

const ToothColorDetection: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate(); // Initialize the navigate hook

  useEffect(() => {
    setupCamera();
  }, []);

  const setupCamera = () => {
    const constraints = {
      video: {
        facingMode: { ideal: 'user' }, // Front camera for mobile devices
      },
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current?.play();
          };
        }
      })
      .catch((err) => {
        console.log('An error occurred: ', err);
        alert('Error accessing camera: ' + err.message);
      });
  };

  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (blob) {
            const formData = new FormData();
            formData.append('file', blob);

            fetch('https://madai.app/webcam', {
              method: 'POST',
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.color_code && data.color_codes) {
                  // Navigate to /result if color_code exists in the response
                  navigate('/result', { 
                    state: { 
                      detectedColor: data.color_code, 
                      colorCodes: data.color_codes } });
                } else {
                  alert('No smile detected or unable to determine color.');
                }
              })
              .catch((error) => {
                console.error('Error:', error);
                alert('Failed to process the photo.');
              });
          }
        }, 'image/jpeg');
      }
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gradient-to-t from-[#008fc9] to-[#050394]">
      <div className="flex flex-col items-center">
        {/* Webcam Container */}
        <div className="relative w-[80vw] max-w-[600px] h-[60vh] bg-black bg-opacity-50 flex items-center justify-center">
          <video
            id="videoFeed"
            ref={videoRef}
            autoPlay
            playsInline
            className="w-full h-full object-cover"
          ></video>
          <div className="absolute inset-0 border-[6px] border-white border-opacity-50 pointer-events-none"></div>
        </div>

        {/* Capture Button */}
        <button
          onClick={capturePhoto}
          className="mt-5 w-12 h-12 rounded-full bg-red-600 fixed bottom-[5vh] z-20 cursor-pointer"
        >
          
        </button>

        {/* Hidden Canvas */}
        <canvas ref={canvasRef} style={{ display: 'none' }} />

        {/* Image Element */}
        <img
          id="emotionImage"
          src=""
          alt="Tooth Color Detection Image"
          className="hidden w-full h-full object-cover absolute top-0 left-0"
        />
      </div>
    </div>
  );
};

export default ToothColorDetection;
