import React, { useState } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  country: string; // Will hold the country name dynamically
  organization: string;
  occupation: string;
  contactNumber: string; // Includes country code and phone number
}

type CountryData = {
  name: string;
  dialCode: string;
  countryCode: string;
};

const PricingList: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormInput>();

  const onContactUs: SubmitHandler<IFormInput> = async (data) => {
    setIsSubmitting(true); // lock the form
    try {
      const response = await fetch("https://madai.app/email/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success("Your request was sent successfully!", {
          position: "bottom-center",
          autoClose: 3000,
        });
      } else {
        toast.error(
          `Request failed: ${result.message || "Please try again."}`,
          {
            position: "bottom-center",
            autoClose: 3000,
          }
        );
      }
      reset();
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="sw-full max-w-xs sm:max-w-sm md:max-w-md 
      lg:max-w-lg mx-auto px-4 sm:px-6 lg:px-8"
       onSubmit={handleSubmit(onContactUs)}>
        <div className="space-y-8">
          <div className="border-b border-gray-900/10 pb-8">
            <div className="text-center">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Personal Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Use a permanent address where you can receive mail.
              </p>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* First Name */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    id="firstName"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 
                    shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("firstName", {
                      required: "First name is required",
                      maxLength: {
                        value: 20,
                        message: "name cannot exceed 20 characters",
                      },
                    })}
                  />
                  {errors.firstName && (
                    <span style={{ color: "red", fontSize: "7px" }}>
                      {errors.firstName.message}
                    </span>
                  )}
                </div>
              </div>

              {/* Last Name */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    id="lastName"
                    type="text"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                    ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                    focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("lastName", {
                      required: "Last name is required",
                      maxLength: {
                        value: 20,
                        message: "name cannot exceed 20 characters",
                      },
                    })}
                  />
                  {errors.lastName && (
                    <span style={{ color: "red", fontSize: "7px" }}>
                      {errors.lastName.message}
                    </span>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                    shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("email", {
                      required: "Email is required",
                      maxLength: 50,
                    })}
                  />
                  {errors.email && (
                    <span style={{ color: "red", fontSize: "7px" }}>
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>

              {/* Organization */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="organization"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Organization
                </label>
                <div className="mt-2">
                  <input
                    id="organization"
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                    ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
                    focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("organization", {
                      required: true,
                      maxLength: 50,
                    })}
                  />
                </div>
              </div>

              {/* Contact Number with Country Code */}
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-900">
                  Contact Number
                </label>
                <Controller
                  name="contactNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Contact number is required",
                    validate: (value) => {
                      // Ensure number is valid and not just the country code
                      return value.length > 5 || "Enter a valid phone number";
                    },
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      country={"us"}
                      value={field.value}
                      onChange={(value, countryData) => {
                        field.onChange(value);
                        const country = countryData as CountryData;
                        if (country && country.name) {
                          setValue("country", country.name); // Set the country name dynamically
                        }
                      }}
                      inputClass="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  )}
                />
                {errors.contactNumber && (
                  <span style={{ color: "red", fontSize: "7px" }}>
                    {errors.contactNumber.message}
                  </span>
                )}
              </div>

              {/* Country */}
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-900">
                  Country
                </label>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      readOnly
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm bg-gray-100"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-center gap-x-6">
          <button
            type="submit"
            disabled={isSubmitting} // Disable button while submitting
            className={`rounded-md bg-black px-10 py-3 text-sm font-semibold text-white
            shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
            focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? "Submitting..." : "Send"}
          </button>
        </div>
      </form>

      <ToastContainer />
    </>
  );
};

export default PricingList;

