import React from 'react';


interface NotificationProps {
    className: string;
    title: string;
}

const Notification: React.FC<NotificationProps> = ({ className, title}) => {
  return (
    <div className={`${className || ""} flex flex-1 items-center p-4 pr-6 bg-n-9/40
    backdrop-blur border border-n-1/10 rounded-2xl gap-5 text-n-1`}>{title}</div>
  )
}

export default Notification