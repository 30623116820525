import React from 'react';
import { loading } from 'src/assets';

interface GeneratingProps {
    className?: string;

}

const Generating: React.FC<GeneratingProps> = ({className}) => {
  return (
    <div className={`flex items-center h-[3.5rem] px-6
     bg-n-4/80 rounded-[1.7rem] ${className || ""} text-base`}>
        <img className='w-5 h-5 mr-4' src={loading} alt="Loading" />
        MadAI is Generating
    </div>
  )
}

export default Generating;
