//import { ClassNames } from '@emotion/react';
import React, {  ReactNode } from 'react';
import brackets from 'src/assets/svg/Brackets';

interface TaglineProps {
    className?: string;
    children?: ReactNode;
    left?: string;
    right?: string;


}

const Tagline: React.FC<TaglineProps> = ({className, children}) => {
  return (
    <div className={`tagline flex items-center ${className || ""} `}>
        {brackets({position: 'left'})}
            <div className="mx-3 text-n-3">
                    {children}
            </div>
        {brackets({position: 'right'})}
    </div>
  )
}

export default Tagline;
