import React, { useEffect, useCallback, useState } from 'react';
import { FaHome, FaGooglePlusG } from "react-icons/fa";
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { login, logout } from './Auth';


interface IFormInput {
    companyname: string;
    name: string;
    email: string;
    password: string;
}

const AuthForm: React.FC = () => {
    const [isLeftPanelActive, setIsLeftPanelActive] = useState(true);   
    const [isSignUpButtonClicked, setIsSignUpButtonClicked] = useState(false);
    const [isSignInButtonClicked, setIsSignInButtonClicked] = useState(false);
    const navigate = useNavigate();
    const tokenExpiryCheckInterval = 60 * 1000; // Check token expiry every 1 minute


    const togglePanel = () => {
        setIsLeftPanelActive(!isLeftPanelActive);
    };

    const signUp = () => {
        setIsSignUpButtonClicked(!isSignUpButtonClicked);
    };

    const signIn = () => {
        setIsSignInButtonClicked(!isSignInButtonClicked);
    };

    // Separate useForm instances for Sign Up and Sign In forms
    const { register: registerSignUp, handleSubmit: handleSubmitSignUp, formState: { errors: errorsSignUp } } = useForm<IFormInput>();
    const { register: registerSignIn, handleSubmit: handleSubmitSignIn, formState: { errors: errorsSignIn } } = useForm<IFormInput>();

    // const onSignUpSubmit: SubmitHandler<IFormInput> = (data) => {
    //     console.log("Sign Up Data: ", data);
    // };

    // const onSignInSubmit: SubmitHandler<IFormInput> = (data) => {
    //     console.log("Sign In Data: ", data);
    // };

     // Separate useForm instances for Sign Up and Sign In forms
    const onSignUpSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const response = await fetch('https://madai.app/auth/api/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_name: data.companyname,
                    name: data.name,
                    email: data.email,
                    password: data.password
                }),
            });

            if (response.ok) {
                console.log('Sign up successful');


                const loginResponse = await fetch('https://madai.app/auth/api/signin', {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: data.email,
                        password: data.password
                    }),
                });

                if (loginResponse.ok) {
                    const responseData = await loginResponse.json();
                    localStorage.setItem('REACT_TOKEN_AUTH_KEY', responseData.access_token);
                    localStorage.setItem('ACCESS_TOKEN_EXPIRY', (Date.now() + (12 * 60 * 60 * 1000)).toString()); // Assuming 15 minutes expiry
                    localStorage.setItem('REFRESH_TOKEN_KEY', responseData.refresh_token);
                    localStorage.setItem('username', responseData.user_name)
                    localStorage.setItem('user_id', responseData.user_id)
                    console.log('Login successful after sign-up');
                    login(responseData.access_token)
                    alert('Signup and Login Successfully');
                    navigate('/'); // Redirect to homepage
                } else {
                    const errorData = await response.json();
                    console.error('Login failed', errorData.message);
                    alert(`Login failed: ${errorData.message || 'Unknown error'}`);
                }

            } else {
                const errorData = await response.json();
                console.error('Sign up failed', errorData.error);
            }
        } catch (error) {
            console.error('Error signing up:', error);
        }
    };

    const onSignInSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const response = await fetch('https://madai.app/auth/api/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data.email,
                    password: data.password
                }),
            });

            if (response.ok) {
                const responseData = await response.json();
                localStorage.setItem('REACT_TOKEN_AUTH_KEY', responseData.access_token);
                localStorage.setItem('ACCESS_TOKEN_EXPIRY', (Date.now() + (12 * 60 * 60 * 1000)).toString()); // Assuming 15 minutes expiry
                localStorage.setItem('REFRESH_TOKEN_KEY', responseData.refresh_token);
                localStorage.setItem('username', responseData.user_name)
                localStorage.setItem('user_id', responseData.user_id)
                console.log('Login successful');
                login(responseData.access_token)
                alert('Login Successfully');
                navigate('/'); // Redirect to homepage
            } else {
                const errorData = await response.json();
                console.error('Login failed', errorData.message);
                alert(`Login failed: ${errorData.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error logging in:', error);
            alert('An error occurred while logging in. Please try again later.');
        }
    }

    // Define the refreshAccessToken function
        const refreshAccessToken = useCallback(async () => {
            try {
                const refreshToken = localStorage.getItem('REFRESH_TOKEN_KEY');
                if (!refreshToken) throw new Error('No refresh token available');
    
                const response = await fetch('https://madai.app/auth/api/refresh', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ refreshToken }),
                });
    
                if (response.ok) {
                    const responseData = await response.json();
                    localStorage.setItem('REACT_TOKEN_AUTH_KEY', responseData.access_token);
                    localStorage.setItem('ACCESS_TOKEN_EXPIRY', (Date.now() + (12 * 60 * 60 * 1000)).toString()); // Update expiry time
                    localStorage.setItem('user_id', responseData.user_id)

                    login(responseData.access_token);
                } else {
                    logout();
                    navigate('/login'); // Redirect to sign-in page
                }
            } catch (error) {
                console.error('Error refreshing access token:', error);
                logout();
                navigate('/login'); // Redirect to sign-in page
            }
        }, [login, logout, navigate]);

        useEffect(() => {
            const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
            const tokenExpiry = localStorage.getItem('ACCESS_TOKEN_EXPIRY');
    
            const checkTokenExpiration = () => {
                if (token && tokenExpiry) {
                    const now = Date.now();
                    if (now > parseInt(tokenExpiry)) {
                        // Token expired, refresh or log out
                        refreshAccessToken();
                    }
                } else {
                    logout();
                    navigate('/login'); // Redirect to sign-in page
                }
            };
    
            // Check token expiration on component mount
            checkTokenExpiration();
    
            // Set an interval to check token expiration periodically
            const interval = setInterval(checkTokenExpiration, tokenExpiryCheckInterval);
    
            // Cleanup the interval on component unmount
            return () => clearInterval(interval);
        }, [refreshAccessToken, tokenExpiryCheckInterval, logout,navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-cover bg-center">
            <div className="relative w-[850px] h-[500px] shadow-lg overflow-hidden rounded-lg">
                {/* Sign Up Section */}
                <form onSubmit={handleSubmitSignUp(onSignUpSubmit)}>
                    <div className={`absolute top-0 right-0 w-[50%] h-full flex flex-col items-center justify-center p-12 transition-all duration-600 ease-in-out transform ${isLeftPanelActive ? 'translate-x-full' : ''}`}>
                        <h1 className="text-2xl font-bold text-black mb-5">Create Account</h1>
                        <div className="flex space-x-4 mb-5">
                            <a href="/" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-black">
                                <FaHome />
                            </a>
                         {/*   <a href="#" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-gray-800">
                                <FaFacebookF />
                            </a> */}
                            <a href="#" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-red-900">
                                <FaGooglePlusG />
                            </a>
                          {/*  <a href="#" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-gray-800">
                                <FaLinkedinIn />
                            </a> */}
                        </div>
                        <span className="text-sm text-white mb-5">or use your email for registration</span>
                        <div className="w-full">
                            <input type="text" id="companyname" placeholder="Company Name" className={`w-full p-2 bg-gray-200 rounded ${isSignUpButtonClicked ? 'my-0' : 'my-2'}`} {...registerSignUp("companyname", { required: true, maxLength: 20 })} />
                            {errorsSignUp.companyname && <span style={{ color: "red", fontSize: "7px" }}>Company name is required</span>}
                            <input type="text" id="signup-name" placeholder="Name" className={`w-full p-2 bg-gray-200 rounded ${isSignUpButtonClicked ? ' my-0' : 'my-2'}`} {...registerSignUp("name", { required: true, maxLength: 20 })} />
                            {errorsSignUp.name && <span style={{ color: "red", fontSize: "7px" }}>Username is required</span>}
                            <input type="email" placeholder="Email" id="signup-email" className={`w-full p-2 bg-gray-200 rounded ${isSignUpButtonClicked ? ' my-0' : 'my-2'}`} {...registerSignUp("email", { required: true, maxLength: 20 })} />
                            {errorsSignUp.email && <span style={{ color: "red", fontSize: "7px" }}>Email is required</span>}
                            <input type="password" id="signup-password" placeholder="Password" className={`w-full p-2 bg-gray-200 rounded ${isSignUpButtonClicked ? ' my-0' : 'my-2'}`} {...registerSignUp("password", { required: true, maxLength: 20 })} />
                            {errorsSignUp.password && <span style={{ color: "red", fontSize: "7px" }}>Password is required</span>}
                        </div>
                        <button type="submit" className="mt-5 px-12 py-2 bg-gray-800 text-white rounded-full" onClick={signUp}>Sign Up</button>
                    </div>
                </form>

                {/* Sign In Section */}
                <form onSubmit={handleSubmitSignIn(onSignInSubmit)}>
                    <div className={`absolute top-0 w-[50%] h-full flex flex-col items-center justify-center p-10 transition-all duration-600 ease-in-out transform ${isLeftPanelActive ? '' : '-translate-x-full'}`}>
                        <h1 className="text-2xl font-bold text-black mb-5">Sign in</h1>
                        <div className="flex space-x-4 mb-5">
                            <a href="/" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-black">
                                <FaHome />
                            </a>
                          {/*  <a href="#" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-gray-800">
                                <FaFacebookF />
                            </a> */}
                            <a href="#" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-red-900">
                                <FaGooglePlusG />
                            </a>
                          {/*  <a href="#" className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-gray-800">
                                <FaLinkedinIn />
                            </a> */}
                        </div>
                        <span className="text-sm text-black mb-5">or use your account</span>
                        <div className="w-full">
                            <input type="email" id='signin-email' placeholder="Email" {...registerSignIn("email", { required: true, maxLength: 20 })} className={`w-full p-3 bg-gray-200 rounded  ${isSignInButtonClicked ? 'my-1' : 'my-1'}`} />
                            {errorsSignIn.email && <span style={{ color: "red", fontSize: "7px" }}>Email is required</span>}
                            <input type="password" id='signin-password' placeholder="Password" {...registerSignIn("password", { required: true, maxLength: 20 })} className={`w-full p-3 bg-gray-200 rounded  ${isSignInButtonClicked ? 'my-1' : 'my-1'}`} />
                            {errorsSignIn.password && <span style={{ color: "red", fontSize: "7px" }}>Password is required</span>}
                        </div>
                        <a href="#" className="text-red-600 text-sm underline mt-3 mb-5">Forgot your password?</a>
                        <button type="submit" className="px-12 py-2 bg-gray-800 text-white rounded-full" onClick={signIn}>Sign In</button>
                    </div>
                </form>

                {/* Overlay Section */}
                <div className={`absolute top-0 right-0 w-[50%] h-full bg-gray-800 text-white flex flex-col items-center justify-center p-10 transition-all duration-600 ease-in-out transform ${isLeftPanelActive ? '' : '-translate-x-full'}`}>
                    {isLeftPanelActive ? (
                        <>
                            <h1 className="text-3xl font-bold">Hello, Friend!</h1>
                            <p className="mt-5 text-center">Enter your personal details and start your journey with us</p>
                            <button className="mt-5 px-12 py-2 bg-transparent border border-white rounded-full" onClick={togglePanel}>Sign Up</button>
                        </>
                    ) : (
                        <>
                            <h1 className="text-3xl font-bold">Welcome Back!</h1>
                            <p className="mt-5 text-center">To keep connected with us please login with email</p>
                            <button className="mt-5 px-12 py-2 bg-transparent border border-white rounded-full" onClick={togglePanel}>Sign In</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuthForm;
