import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IFormInput {
    firstName: string,
    lastName: string,
    email: string,
    country: string,
    organization: string,
    occupation: string,
}


const PricingList: React.FC = () => {

  const {register: registerContactUs, handleSubmit: handleSubmitContact, formState: { errors: errorsContactUs } } = useForm<IFormInput>();

  const onContactUs: SubmitHandler<IFormInput> = async (data) => {
    
    try {
      const response = await fetch('https://madai.app/email/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          country: data.country,
          occupation: data.occupation,
          organization: data.organization

        }),
      });
      const result = await response.json();
      if (response.ok) {
        alert('Email sent successfully!');
      } else {
        alert(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send email.');
    }
  };

  return (
    <form className='w-[70%]' onSubmit={handleSubmitContact(onContactUs)}>
      <div className="space-y-10 ">
        <div className="border-b border-gray-900/10 pb-12 ">
          <div className='flex flex-col items-center justify-center'>
            <h2 className=" text-base font-semibold leading-7 text-gray-900 ">Personal Information</h2>
            <p className=" mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
          </div>
          

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  id="firstName"
                  type="text"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...registerContactUs("firstName", { required: true, maxLength: 20 })}
                  
                />
                 {errorsContactUs.firstName && <span style={{ color: "red", fontSize: "7px" }}>Company name is required</span>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <input
                  id="lastName"
                  type="text"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...registerContactUs("lastName", { required: true, maxLength: 20 })}

                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...registerContactUs("email", { required: true, maxLength: 50 })}

                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Organization
              </label>
              <div className="mt-2">
                <input
                  id="organization"
                  type="text"                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...registerContactUs("organization", { required: true, maxLength: 20 })}

                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Occupation
              </label>
              <div className="mt-2">
                <input
                  id="occupation"
                 
                  type="text"                 
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...registerContactUs("occupation", { required: true, maxLength: 20 })}

                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Country
              </label>
              <div className="mt-2">
                <input
                  id="country"
                  name="country"
                  type="text"
                  
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* Repeat for other fields, updating the `name` attributes and `onChange` handlers */}

          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-center gap-x-6">
        <button
          type="submit"
          className=" rounded-md bg-black px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Contact Us
        </button>
      </div>
    </form>
  );
};

export default PricingList;
