import { useState, useEffect } from 'react';

interface UserInformation {
  userid: string;
  videoId: string;
  projectName: string;
}

export const useUser = (): UserInformation => {
  const [userid, setUserId] = useState<string>('');
  const [videoId, setVideoId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');

  useEffect(() => {
    // Assuming you store the user information in local storage or session storage after the user logs in
    const storedUserId = localStorage.getItem('user_id') || '';
    const storedVideoId = localStorage.getItem('videoId') || '';
    const storedProjectName = localStorage.getItem('projectName') || '';

    setUserId(storedUserId);
    setVideoId(storedVideoId);
    setProjectName(storedProjectName);
  }, []);

  return { userid, videoId, projectName };
};
