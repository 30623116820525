import React from "react";
import { useLocation } from "react-router-dom";
import videoback from '../../assets/BGvideo5.mp4'

const QuizResult: React.FC = () => {
  const location = useLocation();
  const loveType = location.state?.loveType || "Go to love triangle";

    // Define text positions dynamically
    const loveTypePositions: Record<string, { top: string; left: string }> = {
        "True Love": { top: "70%", left: "50%" },
        "Friendship Love": { top: "-50%", left: "50%" },
        "Romantic Love": { top: "50%", left: "-40%" },
        "Deep Bond": { top: "50%", left: "140%" },
        "Crush": { top: "140%", left: "-50%" },
        "Instant Love": { top: "140%", left: "50%" },
        "Empty Love": { top: "140%", left: "150%" },
      };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-5"

><video
    className="absolute top-0 left-0 max-w-md h-full object-cover"
    src={videoback}
    autoPlay
    loop
    muted
/>
      <h1 className="text-lg sm:text-xl md:text-2xl font-bold text-center mb-6">
        Your Love Type: {loveType}
      </h1>

      {/* Triangle Container */}
      <div className="relative flex items-center justify-center w-40 h-40">
        {/* Triangle Shape */}
        <div className="w-0 h-0 border-l-[170px] border-r-[170px] border-b-[250px] border-l-transparent border-r-transparent border-b-pink-100 relative"></div>

        {/* Dynamically Positioned Love Type */}
        <div
          className="absolute text-blue-800 font-bold text-center w-[6.5rem] bg-white rounded-md"
          style={{
            top: loveTypePositions[loveType]?.top || "50%",
            left: loveTypePositions[loveType]?.left || "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {loveType}
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
