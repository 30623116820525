import React from "react";
import Section from "./Section.component";
import service4 from "../../assets/undraw_Creativity_re_8grt (1).png";

const HowtoUse = () => {
  return (
    <Section id="roadmap" className="flex justify-center items-center">
      <div className="container-how flex relative z-2 mt-20 p-4 sm:p-8">
        {/* Content Section */}
        <div className="relative w-full max-w-6xl">
          <div
            className="relative z-1 flex flex-col md:flex-row items-center h-auto p-6 sm:p-8 lg:p-16 bg-gray-100 shadow hover:shadow-2xl rounded-xl 
            border border-n-1/10 overflow-hidden"
          >
            {/* Background Image */}
            <img
              className="absolute w-full h-full object-cover opacity-30 md:opacity-40 lg:opacity-50 right-0 top-0"
              src={service4}
              alt="Smartest AI"
            />

            {/* Text Content */}
            <div className="relative z-2 max-w-full md:max-w-[60%] p-4 sm:p-6">
              <h4 className="text-lg sm:text-xl lg:text-2xl font-bold mb-4">
                A Perfect Blend of Research, Creativity, and Advanced Technology
              </h4>
              <p className="text-sm sm:text-base lg:text-lg text-black leading-relaxed">
                We fuse academic research on advertising, emotions, music,
                films, and aesthetics with insights into audience viewership
                behavior, creating a powerful blend of creativity and
                technology. Using advanced deep learning algorithms and
                generative AI, we craft intelligent, emotionally resonant
                solutions that optimize engagement and impact. By combining the
                art of storytelling with the precision of data-driven insights,
                we redefine how businesses connect with their audiences. Our
                approach delivers innovative, tailored strategies that bridge
                the gap between human connection and technological advancement,
                setting a new standard in creative problem-solving.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HowtoUse;

