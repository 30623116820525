import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MadAI from "../../../assets/MadAi-02.png";
import { BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { SideBarCom } from 'src/constants';
import { IconType } from 'react-icons';

interface ChatHistoryProps {
  userId: string;
  uploadedVideoId?: string;
  projectName: string;
}

interface Conversation {
  id: number;
  name: string;
  timestamp: string;
  // projectName: string;
  video_id: number; // Add video_id field
  messages: Array<{ role: string; message: string }>;
}

const Layout: React.FC<ChatHistoryProps> = ({userId, uploadedVideoId, projectName }) => {

    const pathname = useLocation();

    const [expanded, setExpanded] = useState(true)

    const [active, setActive] = useState<string | null>(null);  // State to track active sidebar item by string id
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [selectedChat, setSelectedChat] = useState<Conversation | null>(null);
    const [chatHistories, setChatHistories] = useState<Conversation[]>([]);

    const handleItemClick = (id: string) => {  // Updated to accept string
        setActive(id);
    }

      const fetchChatHistory = async () => {
    if (userId) {
      try {
        const response = await fetch(`https://madai.app/chatfun/api/get_conversations?user_id=${userId}`);
        const data = await response.json();
        setChatHistories(data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    }
  };

  const handleChatClick = async (chatId: number) => {
    try {
      const response = await fetch(`https://madai.app/chatfun/api/get_conversation?conversation_id=${chatId}`);
      const data = await response.json();
      if (data) {
        // Set selected chat and update project name and video ID
        setSelectedChat({
          ...data,
          projectName: data.name.split(' ')[0], // Extract project name
          video_id: data.video_id, // Assuming video_id is returned
        });
      }
    } catch (error) {
      console.error('Error fetching chat conversation:', error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchChatHistory();
    }
  }, [userId]);


    return (
        <aside className='h-screen flex flex-row'>
            <nav className={`h-full flex flex-col   shadow-sm ${expanded ? "w-56 bg-gray-100":"w-15 bg-white"}`}>
                <div className='p-4 pb-2 flex justify-between items-center'>
                    <img
                        src={MadAI}
                        width={32}
                        height={48}
                        alt="madai"
                        className={`overflow-hidden transition-all 
                                    ${expanded ? "w-20" : "w-0"}`} />

                    <button
                         onClick={() => setExpanded(curr => !curr)}
                         onMouseEnter={() => setIsTooltipVisible(true)}
                         onMouseLeave={() => setIsTooltipVisible(false)}
                         className={`p-1.5 rounded-lg bg-gray-100 hover:bg-gray-300 ${expanded ? "":""}`}>
                        {expanded ? <BsLayoutSidebarInsetReverse /> : <BsLayoutSidebarInsetReverse />}
                    </button>
                   {isTooltipVisible && (
                     <div className="absolute top-5 left-[15%] transform -translate-x-[10px] px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap">
                         Sidebar
                     </div>
                  )}
                </div>

                <ul className={`h-[20%] flex-3 px-4 p-3 ${expanded ? "":"opacity-0 w-0"}`}>
                    {SideBarCom.map((item) => {
                        const Icon: IconType = item.iconUrl;  // Assign the icon component to a variable
                        return (
                            <li key={item.id} >
                                <a
                                href={item.url}
                                onClick={() => handleItemClick(item.id)} // to highloight when click the tab // Pass string id
                                className={`relative flex items-center py-2 px-7 my-1 gap-4 text-sm
                                    font-normal rounded-md cursor-pointer transition-colors
                                    ${active === item.id ? 
                                         "bg-black text-white"
                                        : "hover:bg-gray-300 text-neutral-950"
                                    }`}>
                                    <Icon className="mr-2" /> {/* Call the icon as a component */}
                                    <span className={`w-10 ml-3 ${expanded ? "":"opacity-0"}`}>{item.title}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
                    {/* chat history section */}
                <div className={`h-[75%] flex-1 p-4 bg-zinc-50 ${expanded ? "" : "opacity-0"}`}>
                              <div className='p-2 rounded-md h-[90%] overflow-y-scroll'>
            <ul className=''>
              {chatHistories.length > 0 ? (
                chatHistories.map((history) => (
                  <li
                    key={history.id}
                    className="py-2 relative flex items-center px-7 my-1 gap-4 font-medium text-xs
                    rounded-md cursor-pointer transition-colors bg-gray-200 hover:bg-gray-300"
                    onClick={() => handleChatClick(history.id)}  // Call handleChatClick on click
                  >
                    <div className="flex justify-between items-center">
                      <span>{history.name}</span>
                    </div>
                  </li>
                ))
              ) : (
                <li>No chat history found</li>
              )}
            </ul>
          </div>
                </div>
                    {/* sidebar bottom section section */}
              {/*  <div className={`border-t flex p-2 ${expanded ? "" : "opacity-0"}`}>
                    <img src={MadAI} alt="madai" className='w-10 h-10 rounded-md bg-slate-500' />

                    <div
                        className={`flex justify-between items-center
                                    w-52 ml-3 ${expanded ? "w-52 ml-3" : "w-0"}`
                        }>
                        <div className='flex-1 p-0'>
                            <h4 className={`font-semibold ${expanded ? "":"opacity-0"}`}>Seninda Bandara</h4>
                            <span className={`text-xs text-gray-600 ${expanded ? "":"opacity-0"}`}>senind@gmail.com</span>
                        </div>
                    </div>
                </div> */}
            </nav>

            <div className='p-0 flex-1'>
                        {/* Pass the fetchChatHistory and selectedChat to the Outlet */}
        <Outlet context={{
          fetchChatHistory,
          selectedChat,
          videoId: selectedChat?.video_id ?? uploadedVideoId, // Pass selectedChat's video ID or fallback to uploadedVideoId from props
          projectName: selectedChat?.name.split(' ')[0] ?? uploadedVideoId, // Pass selectedChat's video ID or fallback to uploadedVideoId from props
        }} />
            </div>
        </aside>
    )
}

export default Layout;
